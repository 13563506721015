/* eslint-disable no-undef */
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLinksBox, StyledMenuDropdown } from '../styles/NavbarStyles';

const NavbarButtons = (props) => {
  const [projOpen, setProjOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const { projectLinks, aboutLinks } = props;
  return (
    <NavLinksBox>
      <StyledMenuDropdown onMouseEnter={() => setProjOpen(true)} onMouseLeave={() => setProjOpen(false)}>
        <Button  component={Link} to={'/projects'} disableRipple>Projects</Button>
        <Grow in={projOpen} style={{ transformOrigin: 'top center' }}>
          <List>
            {projectLinks.map(link =>               
              <ListItem key={link.id} disablePadding>
                <ListItemButton component={Link} to={link.subRoute}>
                  <ListItemText primary={link.name} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grow>
      </StyledMenuDropdown>
      <StyledMenuDropdown onMouseEnter={() => setAboutOpen(true)} onMouseLeave={() => setAboutOpen(false)}>
        <Button  component={Link} to={'/about'} disableRipple>About</Button>
        <Grow in={aboutOpen} style={{ transformOrigin: 'top center' }}>
          <List>
            {aboutLinks.map(link =>               
              <ListItem key={link.id} disablePadding>
                <ListItemButton component={Link} to={link.subRoute}>
                  <ListItemText primary={link.name} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grow>
      </StyledMenuDropdown>
      <Button component={Link} to='/resume'>Resume</Button>
    </NavLinksBox>
  )
};
export default NavbarButtons;

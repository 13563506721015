import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/Loading';
import home from './data/home';
import aboutme from './data/aboutme';
import resume from './data/resume';
import scad from './data/scad';
import projects from './data/projects';
import freelance from './data/freelance';
import educational from './data/educational';
import personal from './data/personal';
import mainTheme from './styles/mainTheme';
import './styles/App.css';

const Home = React.lazy(() => import( './components/Home'))
const About = React.lazy(() => import( './components/About'))
const Resume = React.lazy(() => import( './components/Resume'))
const College = React.lazy(() => import( './components/College'))
const Projects = React.lazy(() => import( './components/Projects'))
const ProjectPage = React.lazy(() => import( './components/ProjectPage'))
const FreelanceProject = React.lazy(() => import( './components/FreelanceProject'))
const EducationalProject = React.lazy(() => import( './components/EducationalProject'))
const PersonalProject = React.lazy(() => import( './components/PersonalProject'))
// const NotFound = React.lazy(() => import( './components/NotFound'))

function App() {
  const location = useLocation();
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Navbar />
      <React.Suspense fallback={<Loading />}>
        <Routes location={location}>
          <Route path='/' element={<Home  {...home} />} /> 
          <Route path='/about' element={<About {...aboutme} />} />
          <Route path='/resume' element={<Resume {...resume} />} /> 
          <Route path='/college' element={<College {...scad} />} /> 
          <Route path='/projects' element={<Projects info={projects} /> } />
          <Route path='/freelance' element={<ProjectPage projects={freelance} type='freelance'/>}>
            <Route path=':projectId' element={ <FreelanceProject /> } />
          </Route>
          <Route path='/educational' element={<ProjectPage projects={educational} type='educational'/>}>
            <Route path=':projectId' element={ <EducationalProject /> } />
          </Route>
          <Route path='/personal' element={<ProjectPage projects={personal} type='personal'/>}>
            <Route path=':projectId' element={ <PersonalProject /> } />
          </Route>
          <Route path='*' element={<Home  {...home} />} />
        </Routes>
      </React.Suspense>
      <ScrollToTop />
    </ThemeProvider>
  );
}

export default App;


import oasisImg from '../assets/images/projects/oasis-card.png';
import studyMateImg from '../assets/images/projects/studymate-card.png';
import ghpImg from '../assets/images/projects/ghp-card.png';
import reactColorImg from '../assets/images/projects/react-card.png'
import yelpcampImg from '../assets/images/projects/yelpcamp-card.png';
import anniversaryImg from '../assets/images/projects/anniversary-card.png'
/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
const projects = {
	casestudy: {
			preview: {
				id: 'studymate-card',
				title: 'Study Mate',
				year: '2023',
				image: studyMateImg, 
				alt: 'Link to Study Mate project on Behance',
				summary: 'The Study Mate App is a platform that provides a service for group study, designed to cater to the needs of students of all ages, backgrounds, and levels of education.',
				link: 'https://www.behance.net/gallery/165755283/StudyMate-UIUX-Case-Study'
			}
		},
	freelance: [
		{
			preview: {
				id: 'oasis-card',
				title: 'Oasis Yacht Seattle',
				year: '2021',
				image: oasisImg, 
				alt: 'oasis project',
				summary: 'The Oasis is a luxury yacht available to charter on Lake Union, Lake Washington and the Puget Sound.',
				link: '/freelance/oasis'
			}
		},
		{
			preview: {
				id: 'ghp-card',
				title: 'Greg Holloway Photography',
				year: '2020',
				image: ghpImg, 
				alt: 'greg holloway photography project',
				summary: 'This photography site features a wide range of photo collections from local parties to international events.',
				link: '/freelance/ghp'
			}
		},
	],
	educational: [
		{
			preview: {
				id: 'reactcolor-card',
				title: 'React Color Project',
				year: '2021',
				image: reactColorImg, 
				alt: 'react color project',
				summary: 'Capstone project for the "Modern React Bootcamp" course by instructor Colt Steele.',
				link: '/educational/reactcolor'
			}
		},
		{
			preview: {
				id: 'yelpcamp-card',
				title: 'Yelpcamp Project',
				year: '2020',
				image: yelpcampImg, 
				alt: 'yelpcamp project',
				summary: 'Capstone project for the "Web Development Bootcamp" course by instructor Colt Steele.',
				link: '/educational/yelpcamp'
			}
		}
	],
	personal: [
		{
			preview: {
				id: 'anniversary-card',
				title: 'Love Letter',
				year: '2020',
				image: anniversaryImg, 
				alt: 'anniversary project',
				summary: 'Anniversary gift for my husband of 12 years.',
				link: '/personal/anniversary'
			}
		}
	]
}
export default projects;
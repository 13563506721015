import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ReactComponent as BehanceIcon } from '../assets/images/graphics/behance-svgrepo-com.svg';
const resume = {
	sideImages: {
		pictureOne: {src: require('../assets/images/profile-couple.png'), alt: 'profile picture'},
		pictureTwo: {src: require('../assets/images/profile-art.png'), alt: 'profile picture'},
		pictureThree: {src: require('../assets/images/profile-coffee.png'), alt: 'profile picture'},
		pictureFour: {src: require('../assets/images/profile-wings.png'), alt: 'profile picture'},
		pictureFive: {src: require('../assets/images/profile-tulips.png'), alt: 'profile picture'},
	},
	profile: {
		name: 'Kristina McCool',
		job: 'Web Designer & Front End Developer',
		resume: {name: 'Download Resume', icon: <FileDownloadIcon />, src: require('../assets/images/Resume2024.pdf')},
		social: [
			{type: 'Go to LinkedIn page', link: 'https://www.linkedin.com/in/k-mccool-canvas/', icon: <LinkedInIcon />, alt: 'linkedin' },
			{type: 'Go to Github page', link: 'https://github.com/km-canvas', icon: <GitHubIcon />, alt: 'github' },
			{type: 'Go to Behance page', link: 'https://www.behance.net/km281', icon: <BehanceIcon />, alt: 'behance' }
		],
		education: [
			{
				type: 'University', 
				name: 'Savannah College of Art and Design', 
				courses: [
					{name: 'Architecture', date: '2003 - 2008'},
					{name: '2D & 3D Fine Arts', date: '2003-2004'},
					{name: 'Ceramics', date: '2006-2007'},
				], 
				degree: 'Bachelor of Fine Arts in Architecture and Master in Architecture'
			},
			{
				type: 'Online Learning', 
				name: 'Udemy.com', 
				courses: [
					{ name: 'The Web Developer Bootcamp', date: '2020' },
					{ name: 'The Modern React Bootcamp', date: '2021' },
					{ name: 'Complete Web & Mobile Designer', date: '2022' }
				],
				degree: 'Certificates of Completion'
			}
		],
	},
	techSkills: [
		{
			area: 'Product Design',
			list: [
				{ skill: 'UX/UI Design', proficiency: 5 },
				{ skill: 'Wireframing', proficiency: 5 },
				{ skill: 'Advanced Protoyping', proficiency: 4 },
				{ skill: 'Creative Problem Solving', proficiency: 5 },
				{ skill: 'Design Documentation', proficiency: 5 },
				{ skill: 'Design Systems', proficiency: 5 },
			]
		},
		{
			area: 'Tools & Media',
			list: [
				{ skill: 'Figma', proficiency: 5 },
				{ skill: 'Jira',proficiency: 5 },
				{ skill: 'Confluence',proficiency: 4 },
				{ skill: 'Photoshop', proficiency: 3 },
				{ skill: 'Illustrator', proficiency: 3 },
				{ skill: 'Gimp', proficiency: 3 },
				{ skill: 'Excel', proficiency: 4 },
				{ skill: 'Canva', proficiency: 5 },
			]
		},
		{
			area: 'DOM, Stylesheets, Scripts, Libraries',
			list: [
				{ skill: 'React', proficiency: 4 },
				{ skill: 'HTML5', proficiency: 5 },
				{ skill: 'CSS', proficiency: 5 },
				{ skill: 'CSS-in-JS', proficiency: 4 },
				{ skill: 'Bootstrap', proficiency: 3 },
				{ skill: 'Material UI', proficiency: 4 },
				{ skill: 'JavaScript', proficiency: 4 },
				{ skill: 'jQuery', proficiency: 3 },
				{ skill: 'Embedded JavaScript', proficiency: 3 },
				{ skill: 'npm', proficiency: 4 },
			]
		},
		{
			area: 'Frameworks & Backend',
			list: [
				{ skill: 'Node.js', proficiency: 3 },
				{ skill: 'Express', proficiency: 2 },
				{ skill: 'MongoDB', proficiency: 2 },
				{ skill: 'Mongoose', proficiency: 2 },
				{ skill: 'Git', proficiency: 3 },
			]
		},
	],
	relatedSkills: [
		{
			area: 'Personal',
			list: [ 'Adaptable', 'Organized', 'Fastidious', 'Reliable', 'Proactive' ]
		},
		{
			area: 'Interpersonal',
			list: [ 'Good Communicator','Friendly', 'Respectful',  'Attentive', 'Honest' ]
		}
	],
}
export default resume;
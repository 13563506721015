import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import { keyframes } from '@mui/system';
 
const fadeIn = keyframes`
	100% { opacity: 1; }
`
export const StyledAppBar = styled(AppBar, {
	name: 'StyledAppBar',
	slot: 'wrapper'
})(({theme, location}) => ({
	height: '64px',
	'& .MuiTypography-appbarLogo': {
		color: theme.palette.accentPrimary.contrastText,
		opacity: (location === '/') ? 0 : 1,
		animation: (location === '/') ? `1s ease 5s forwards ${fadeIn} ` : 'none',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem'
		}
	}
}))

export const NavLinksBox = styled('div')(({ theme }) => ({
	flexGrow: 1,
	display: 'none',
	'@media (min-width:900px)': {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	'& .MuiButton-root': {
		fontSize: '1.2rem', 
		lineHeight: '1.2rem',
		display: 'block', 
		padding: theme.spacing(4),
		minWidth: 160,
		textAlign: 'center',
		background: theme.palette.baseDark.main,
		color: theme.palette.baseDark.contrastText,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
}))

export const StyledMenuDropdown = styled('div')(({ theme }) => ({
	position: 'relative',
	display: 'inline-block',
	'& .MuiList-root': {
		minWidth: 160,
		position: 'absolute',
		zIndex: theme.zIndex.appbar + 1,
		paddingTop: 0,
		paddingBottom: 0,
		color: theme.palette.baseDark.contrastText,
		backgroundColor: theme.palette.baseDark.main,
		borderBottomLeftRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		overflow: 'hidden',
		'& .MuiListItem-root': {
			'& .MuiListItemButton-root': {
				transition: 'color 300ms',
			},
			'&:hover .MuiListItemButton-root': {
				background: theme.palette.accentPrimary.dark
			},
			'& .MuiListItemText-root': {
				textAlign: 'center'
			}
		}
	}
}))

export const MenuLinksBox = styled('div')(({ theme }) => ({
	display: 'flex',
	'& .MuiSvgIcon-root': {
		color: theme.palette.accentPrimary.contrastText
	},
	'@media (min-width:900px)': {
		display: 'none'
	},
}))

export const StyledDrawerPrimary = styled(Drawer, {
	name: 'StyledDrawerPrimary',
	slot: 'Wrapper'
})(({theme}) => ({
	'& .MuiBackdrop-root': {
		backgroundImage: theme.palette.gradient.angledToLavender
	},
	'& .MuiDrawer-paper': {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.light,
		minWidth: 200,
		width: 'fit-content',
		minHeight: 200,
		height: 'auto',
		borderBottomLeftRadius: '100%',
		overflow: 'hidden',
	},
	'& .MuiListItemButton-root': {
		padding: theme.spacing(1, 4),
		transition: theme.transitions.create("background", {
			duration: theme.transitions.duration.standard
		}),
		'&:hover': {
			background: theme.palette.gradient.menuLinksPrimary
		},
		'& .MuiTypography-root': {
			fontSize: '1.2rem',
			textAlign: 'right',
			textTransform: 'uppercase',
		}
	}
}))
export const StyledDrawerSecondary = styled(StyledDrawerPrimary, {
	name: 'StyledDrawerSecondary',
	slot: 'Wrapper'
})(({theme}) => ({
	'& .MuiBackdrop-root': {
		backgroundImage: theme.palette.gradient.angledToLavender
	},
	'& .MuiDrawer-paper': {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.accentPrimary.contrastText,
		backgroundColor: theme.palette.accentPrimary.dark,
		'& .MuiListItemButton-root:hover': {
			background: theme.palette.gradient.menuLinksSecondary
		},
		'& .MuiIconButton-root': {
			position: 'absolute',
			bottom: theme.spacing(-10),
			right: theme.spacing(4),
			'& .MuiSvgIcon-root': {
				fontSize: '2rem',
				fill: theme.palette.accentPrimary.contrastText
			}
		}
	},
}))

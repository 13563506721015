/* eslint-disable no-undef */
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuIcon from '@mui/icons-material/Menu';
import useToggleState from '../hooks/useToggleState';
import { MenuLinksBox, StyledDrawerPrimary, StyledDrawerSecondary } from '../styles/NavbarStyles';

const Navbar = (props) => {
  const [drawer, handleDrawer ] = useToggleState(false)
  const [projDrawer, handleProjDrawer ] = useToggleState(false)
  const [aboutDrawer, handleAboutDrawer ] = useToggleState(false)
  const { projectLinks, aboutLinks } = props;
  return (
    <MenuLinksBox>
      <IconButton
        size='large'
        aria-label='menu-navigation'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleDrawer}
      >
        <MenuIcon/>
      </IconButton>
      <StyledDrawerPrimary anchor='right' open={drawer} onClose={handleDrawer}>
        <Box role='presentation' onClick={handleDrawer}>
          <List>
            <ListItemButton onClick={handleProjDrawer}>
              <ListItemText primary='Projects' />
            </ListItemButton>
            <ListItemButton onClick={handleAboutDrawer}>
              <ListItemText primary='About' />
            </ListItemButton>
            <ListItemButton component={Link} to={'/resume'}>
              <ListItemText primary='Resume' />
            </ListItemButton>
          </List>
        </Box>
      </StyledDrawerPrimary>
      <StyledDrawerSecondary anchor='right' open={projDrawer} onClose={handleProjDrawer}>
        <Box role='presentation' onClick={handleProjDrawer}>
          <List>
            {projectLinks.map((link) => (
              <ListItemButton key={link.id} component={Link} to={link.subRoute}>
                <ListItemText primary={link.name} />
              </ListItemButton>
            ))}
            <IconButton onClick={handleDrawer} aria-label='go-back'>
              <NavigateNextIcon />
            </IconButton>
          </List>
        </Box>
      </StyledDrawerSecondary>
      <StyledDrawerSecondary anchor='right' open={aboutDrawer} onClose={handleAboutDrawer}>
        <Box role='presentation' onClick={handleAboutDrawer}>
          <List>
            {aboutLinks.map((link) => (
              <ListItemButton key={link.id} component={Link} to={link.subRoute}>
                <ListItemText primary={link.name} />
              </ListItemButton>
            ))}
            <IconButton onClick={handleDrawer} aria-label='go-back'>
              <NavigateNextIcon />
            </IconButton>
          </List>
        </Box>
      </StyledDrawerSecondary>
    </MenuLinksBox>
  )
};
export default Navbar;

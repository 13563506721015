const scad = {
	sideImages: {
		pictureOne: {src: require('../assets/images/architecture/scad-site.png'), alt: 'scad image'},
		pictureTwo: {src: require('../assets/images/architecture/scad-logo.png'), alt: 'scad image'},
		pictureThree: {src: require('../assets/images/architecture/scad-thesis.png'), alt: 'scad image'},
		pictureFour: {src: require('../assets/images/architecture/scad-model.png'), alt: 'scad image'},
		pictureFive: {src: require('../assets/images/architecture/scad-elevation.png'), alt: 'scad image'},
	},
	generalInfo: {
		title: 'Now this takes me back',
		subtitle: 'Shall we take a trip down memory lane?',
		about: 'The five wonderful years I spent at Savannah College of Art and Design will forever influence how I design solutions.'
	},
	portfolio: {
		title: 'Portfolio',
		subtitle: 'Collection of studio work',
		about: 'The collection of work that helped earn my admission to SCAD\'s master\'s degree program.',
		fullbook: {title: 'Portfolio Review Book', src: require('../assets/images/architecture/PortfolioBooklet.pdf'), alt: 'fourth year portfolio review'},
	},
	miniPortfolio: {
		title: 'Miniature Portfolio',
		subtitle: 'Preview of projects and skills',
		about: 'Playing cards with family and friends has always been my favorite activity at gatherings.  So I created a mini portfolio to reflect my interests.  I designed a card deck of projects complete with a case and mailed it out during my initial job search. Here\'s a preview of some of those cards.',
		images: [
			{ name: 'Mini Portfolio Card Back', src: require('../assets/images/architecture/mp-back.png'), alt: 'card back side'},
			{ name: 'Brick Carving Project', src: require('../assets/images/architecture/mp-brick.png'), alt: 'brick carving'},
			{ name: 'AutoCAD Project', src: require('../assets/images/architecture/mp-cad-bridge1.png'), alt: 'campo volantin bridge'},
			{ name: 'AutoCAD Project', src: require('../assets/images/architecture/mp-cad-bridge2.png'), alt: 'campo volantin bridge'},
			{ name: 'Ceramics Project', src: require('../assets/images/architecture/mp-ceramics-perspective.png'), alt: 'ceramic project'},
			{ name: 'Ceramics Project', src: require('../assets/images/architecture/mp-ceramics-serving.png'), alt: 'ceramic project'},
			{ name: 'Ceramics Project', src: require('../assets/images/architecture/mp-ceramics-tea.png'), alt: 'ceramic project'},
			{ name: 'Floor Plan Drawing', src: require('../assets/images/architecture/mp-draw.png'), alt: 'drawing up'},
			{ name: 'Minimal Footprint Ecology Research House', src: require('../assets/images/architecture/mp-eco-day.png'), alt: 'ecology house daytime'},
			{ name: 'Minimal Footprint Ecology Research House', src: require('../assets/images/architecture/mp-eco-night.png'), alt: 'ecology house nighttime'},
			{ name: 'Site Planning for a High Speed Ferry', src: require('../assets/images/architecture/mp-ferry-site.png'), alt: 'high speed ferry site plan'},
			{ name: 'Affordable Housing', src: require('../assets/images/architecture/mp-fl-housing.png'), alt: 'affordable housing in Florida'},
			{ name: 'Microstation Project', src: require('../assets/images/architecture/mp-micro-studio.png'), alt: 'microstation project'},
			{ name: 'Concept Model for an Ice Rink and Climbing Arena', src: require('../assets/images/architecture/mp-model-arena.png'), alt: 'climbing and ice rink concept model'},
			{ name: 'Concept Model for a Hotel on the Water', src: require('../assets/images/architecture/mp-model-sail.png'), alt: 'ocean front hotel model'},
			{ name: 'Concept Model for a Multi-story Store', src: require('../assets/images/architecture/mp-model-store.png'), alt: 'multi-story store model'},
			{ name: 'Concept Sketch for Remodeled Airport to Glider School', src: require('../assets/images/architecture/mp-urban-glider.png'), alt: 'sketch of glider school plan'},
			{ name: 'Concept Sketch for Road Expansion', src: require('../assets/images/architecture/mp-urban-planning.png'), alt: 'sketch of road expansion plan'},
			{ name: 'Day View of Thesis Project', src: require('../assets/images/architecture/mp-revit-mtc-day.png'), alt: 'thesis project'},
			{ name: 'Night View of Thesis Project', src: require('../assets/images/architecture/mp-revit-mtc-night.png'), alt: 'thesis project'},
		]
	},
	thesis: {
		title: 'Master\'s Thesis',
		subtitle: 'Creating Healing Environments',
		about: 'A music therapy center in Chattanooga, TN. The concept of my thesis was inspired by a quote I came across relating architecture to frozen music. It developed into a study with the focus on healing - since architecture can be considered as frozen music and music itself has healing attributes, architecture should also be able to promote healing.',
		fullbook: {title: 'Master\'s Thesis Book', src: require('../assets/images/architecture/ThesisBook.pdf'), alt: 'Masters Thesis Book from SCAD'},
		images: [
			{name: 'Day Aerial', src: require('../assets/images/architecture/thesis-aerial-day.png'), alt: 'aerial view during day'},
			{name: 'Night Aerial', src: require('../assets/images/architecture/thesis-aerial-night.png'), alt: 'aerial view at night'},
			{name: 'Volume Diagram', src: require('../assets/images/architecture/thesis-volume-diagram.png'), alt: 'diagram of volume planning', panorama: true},
			{name: 'Floorplan', src: require('../assets/images/architecture/thesis-floorplan-drawing.png'), alt: 'floorplan'},
			{name: 'Floorplan (rendered)', src: require('../assets/images/architecture/thesis-floorplan.png'), alt: 'rendered floorplan'},
			{name: 'North Elevation', src: require('../assets/images/architecture/thesis-nocontext-north-elevation.png'), alt: 'north elevation', panorama: true},
			{name: 'North Elevation (with site context)', src: require('../assets/images/architecture/thesis-context-north-elevation.png'), alt: 'north elevation with surrounding context', panorama: true},
			{name: 'Front Entrance', src: require('../assets/images/architecture/thesis-entry-front.png'), alt: 'front entrance'},
			{name: 'Lobby', src: require('../assets/images/architecture/thesis-lobby.png'), alt: 'lobby'},
			{name: 'South Elevation', src: require('../assets/images/architecture/thesis-nocontext-south-elevation.png'), alt: 'south elevation', panorama: true},
			{name: 'South Elevation (with site context)', src: require('../assets/images/architecture/thesis-context-south-elevation.png'), alt: 'south elevation with surrounding context', panorama: true},
			{name: 'Conference Room', src: require('../assets/images/architecture/thesis-conference-room.png'), alt: 'conference room'},
			{name: 'Back Entrance', src: require('../assets/images/architecture/thesis-entry-back.png'), alt: 'back entrance'},
			{name: 'East Elevation', src: require('../assets/images/architecture/thesis-nocontext-east-elevation.png'), alt: 'east elevation', panorama: true},
			{name: 'East Elevation (with site context)', src: require('../assets/images/architecture/thesis-context-east-elevation.png'), alt: 'east elevation with surrounding context', panorama: true},
			{name: 'Hallway', src: require('../assets/images/architecture/thesis-hall1.png'), alt: 'hallway'},
			{name: 'Waiting Lounge', src: require('../assets/images/architecture/thesis-lounge.png'), alt: 'lounge'},
			{name: 'West Elevation', src: require('../assets/images/architecture/thesis-nocontext-west-elevation.png'), alt: 'west elevation', panorama: true},
			{name: 'West Elevation (with site context)', src: require('../assets/images/architecture/thesis-context-west-elevation.png'), alt: 'west elevation with surrounding context', panorama: true},
			{name: 'Hallway', src: require('../assets/images/architecture/thesis-hall2.png'), alt: 'hallway'},
			{name: 'Small Therapy Room', src: require('../assets/images/architecture/thesis-sm-sound-room.png'), alt: 'small therapy room'},
			{name: 'Large Therapy Room', src: require('../assets/images/architecture/thesis-sound-room.png'), alt: 'large therapy room'},
			{name: 'Recording Studio', src: require('../assets/images/architecture/thesis-studio.png'), alt: 'recording studio'},
			{name: 'Entry Walkway', src: require('../assets/images/architecture/thesis-walkway.png'), alt: 'walkway to entrance'},
			{name: 'Outdoor Stadium', src: require('../assets/images/architecture/thesis-stadium-top.png'), alt: 'view of stadium from top'},
			{name: 'Outdoor Water Piano', src: require('../assets/images/architecture/thesis-waterpiano.png'), alt: 'outdoor water piano'},
			{name: 'Outdoor Earth Drums', src: require('../assets/images/architecture/thesis-earthdrums.png'), alt: 'earth drums'},
			{name: 'Outdoor Stage', src: require('../assets/images/architecture/thesis-stage.png'), alt: 'stadium stage'},
			{name: 'Clip of Final Exhibit Board', src: require('../assets/images/architecture/thesis-partial-board.png'), alt: 'clip of final exhibit board'},
		],
	}
}
export default scad;
import TodayIcon from '@mui/icons-material/Today';
import BathtubIcon from '@mui/icons-material/Bathtub';
import GamepadIcon from '@mui/icons-material/Gamepad';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AlarmIcon from '@mui/icons-material/Alarm';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import CookieIcon from '@mui/icons-material/Cookie';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import TapasIcon from '@mui/icons-material/Tapas';
import RepeatIcon from '@mui/icons-material/Repeat';
import CodeIcon from '@mui/icons-material/Code';
import BugReportIcon from '@mui/icons-material/BugReport';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { ReactComponent as LayoutIcon} from '../assets/images/graphics/plans-planning-svgrepo-com.svg';

const home = {
	intro: {
		image: {src: require('../assets/images/profile-art.png'), alt: 'profile pic'},
		images: [
			{src: require('../assets/images/profile-art.png'), alt: 'profile pic'},
			{src: require('../assets/images/profile-coffee.png'), alt: 'profile pic'},
			{src: require('../assets/images/profile-street.png'), alt: 'profile pic'},
			{src: require('../assets/images/profile-wings.png'), alt: 'profile pic'},
			{src: require('../assets/images/profile-tree.png'), alt: 'profile pic'},
		],
		title: 'Kris McCool',
		subtitles: [
			{variant: 'h4', color: 'accentPrimary.dark', text: 'Product Designer'},
			{variant: 'h6', color: 'accentPrimary.dark', text: 'And overall charming human being'},
			{variant: 'body1', color: 'primary.dark', text: 'As a product designer, I blend font-end development expertise with a passion for creating intuitive user experiences. My experience extends to building design systems and user interfaces for a B2B/B2C enterprise application, e-commerce websites, and freelancing design/development services for smaller businesses.'},
			{variant: 'body1', color: 'primary.dark', text: 'I enjoy collaborating with creative people to bring a bit of delight to a user\'s journey.  Take a look around and contact me if you are interested in working together.'}
		],
	},
	timeline: [
		{ 
			icon: <TodayIcon />, 
			title: 'A day in the life', 
			subtitle: '',
			opposite: 'unless it\'s my day off',
		},
		{ 
			icon: <AlarmIcon />, 
			title: 'Wake up', 
			subtitle: '',
			opposite: 'zzz...just 5 more minutes',
		},
		{ 
			icon: <CoffeeMakerIcon />, 
			title: 'Wake up some more', 
			subtitle: 'Give me a sec',
			opposite: 'coffee first, design later',
		},
		{ 
			icon: <DesignServicesIcon />, 
			title: 'Get creative', 
			subtitle: 'So many ideas, so little time',
			opposite: 'oof, running out of paper',
		},
		{ 
			icon: <LocalDiningIcon />, 
			title: 'Fuel up', 
			subtitle: '',
			opposite: 'so I can keep coding',
		},
		{ 
			icon: <CodeIcon />, 
			title: 'Code', 
			subtitle: '',
			opposite: 'bring my creations to life',
		},
		{ 
			icon: <CoffeeMakerIcon />, 
			title: 'First 2nd Cup', 
			subtitle: 'To combat after-lunch sleepiness',
			opposite: 'you get it',
		},
		{ 
			icon: <CookieIcon />, 
			title: 'Refuel', 
			subtitle: 'Gotta satisfy my sweet tooth',
			opposite: '...or else',
		},
		{ 
			icon: <LayoutIcon />, 
			title: 'Design & Code some more', 
			subtitle: '',
			opposite: 'cuz it\'s fun',
		},
		{ 
			icon: <BugReportIcon />, 
			title: 'Oops!', 
			subtitle: 'Fix the bugs',
			opposite: 'don\'t look yet, almost done',
		},
		{ 
			icon: <CoffeeIcon />, 
			title: 'Second 2nd Cup', 
			subtitle: 'Yes, you\'re reading that correctly',
			opposite: <span>don't judge,<br/>I only have 2 cups a day</span>
		},
		{ 
			icon: <DirectionsWalkIcon />, 
			title: 'Get fresh air', 
			subtitle: '',
			opposite: 'I hear sunshine is good for you',
		},
		{ 
			icon: <GamepadIcon />, 
			title: 'Play', 
			subtitle: '*Cough*...for design inspiration',
			opposite: 'oh come on, you do it too',
		},
		{ 
			icon: <TapasIcon />, 
			title: 'Eat again', 
			subtitle: 'Yes, I only eat 2 meals a day',
			opposite: 'don\'t worry about it',
		},
		{ 
			icon: <BathtubIcon />, 
			title: 'Clear my head', 
			subtitle: 'And practice singing',
			opposite: <span>what?<br />the acoustics are good</span>
		},
		{ 
			icon: <BedtimeIcon />, 
			title: 'Dreamland', 
			subtitle: 'Do not disturb',
			opposite: <span>shhh,<br />it's getting to the good part</span>,
		},
		{ 
			icon: <RepeatIcon />, 
			title: 'Loop it', 
			subtitle: 'Because I like repetition',
			opposite: <span>days.map(day =&gt; (day.on !== "rest")<br />
			? day.routine : "sleeping in...zzz")</span>,
		},
	]
}
export default home;
import React from 'react';
import Box from '@mui/material/Box';
import { Transition } from 'react-transition-group';
import LinearProgress from '@mui/material/LinearProgress';
import logo from '../assets/images/graphics/kmlogo.png'

const Loading = () => {
	return (
		<Transition in={true} timeout={600} mountOnEnter unmountOnExit>
			{(state) => (
				<Box sx={{ 
					position: 'relative', 
					width: '100%', 
					height: 'calc(100vh - 64px)',
					opacity: (state === 'entering' || state === 'exited') ? 0 : 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<Box 
						sx={{
							position: 'relative',
							width: 160,
							height: 160,
							backgroundImage: `url(${logo})`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center',
							backgroundSize: 'contain',
							borderRadius: '50%',
							opacity: 0.2,
							overflow: 'visible'
						}}
					>
						<Box 
							sx={{
								position: 'absolute',
								width: '200px',
								top: 66,
								left: -20, 
								height: 18,
								paddingTop: '5px',
								backgroundColor: 'primary.main'
							}}
						>
							<LinearProgress color='accentPrimary' 
								sx={{
									height: 8,
									backgroundColor: 'baseDark.light'
								}}
							/>
						</Box>
					</Box>
						
				</Box>
			)}
		</Transition>
	)
}
export default Loading;
import React from 'react';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SellIcon from '@mui/icons-material/Sell';
import EmailIcon from '@mui/icons-material/Email';
import SailingIcon from '@mui/icons-material/Sailing';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import PortraitIcon from '@mui/icons-material/Portrait';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SchemaIcon from '@mui/icons-material/Schema';
import CollectionsIcon from '@mui/icons-material/Collections';
import WarningIcon from '@mui/icons-material/Warning';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as EighteenUp} from '../assets/images/graphics/plus-18-svgrepo-com.svg';
import { ReactComponent as ResearchIcon} from '../assets/images/graphics/thinking-process-svgrepo-com.svg';
import { ReactComponent as LayoutIcon} from '../assets/images/graphics/plans-planning-svgrepo-com.svg';
import oasisImg from '../assets/images/projects/oasis-card.png';
import ghpImg from '../assets/images/projects/ghp-card.png';
/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
const freelance = [
	{
		id: 'oasis',
		title: 'Oasis Yacht Seattle',
		subtitle: 'Luxury Charters',
		goal: 'Design a luxury yacht charter site utilizing the Squarespace platform.',
		year: '2021',
		site: { title: 'Visit Oasis Website', src: 'https://www.oasisyachtseattle.com/', icon: <OpenInNewIcon />, alt: 'Link to Oasis website'},
		preview: {
			title: 'Oasis Yacht Seattle',
			year: '2021',
			image: oasisImg, 
			alt: 'oasis project',
			summary: 'The Oasis is a luxury yacht available to charter on Lake Union, Lake Washington and the Puget Sound.',
		},
		client: {
			type: 'Client',
			name: 'Greg Holloway',
			title: 'Owner',
		},
		srcPrefix: '../assets/images/oasis/',
		avatar: { src: require('../assets/images/oasis/Oasis-graphics-avatar.jpg'), alt: 'Oasis Yacht Avatar' },
		logo: { src: require('../assets/images/oasis/Oasis-graphics-logoBlue.png'), alt: 'Oasis Logo' },
		logoVariation: { src: require('../assets/images/oasis/Oasis-graphics-logoBlack.png'), alt: 'Oasis Logo' },
		resources: [
			{ frontEnd: ['HTML', 'CSS', 'JavaScript'] },
			{ platforms: ['Squarespace'] },
		],
		videos: {
			mobile: {src: '', alt: ''},
			desktop: {src: '', alt: ''},
		},
		tabs: [
			{name: 'process', icon: <ResearchIcon />, link: '#process' },
			{name: 'planning', icon: <SchemaIcon />, link: '#planning' },
			{name: 'layout', icon: <LayoutIcon />, link: '#layout' },
			{name: 'gallery', icon: <CollectionsIcon />, link: '#gallery' },
		],
		process: [
			{
				id: 'concept',
				title: 'Concept',
				description: 'The Oasis is a luxury yacht for charter. When designing the site, we wanted it to have an experiential focus--allowing the visitor to imagine themselves aboard and open up the possibilities of what could be.',
			},
			{
				id: 'schema',
				title: 'Schema',
				description: 'The soft blue and light green colors were utilized to create a tranquil feel. We opted to remove any sharp lines and edges to reflect the smooth curves of the yacht.'
			},
			{
				id: 'platform',
				title: 'CMS Platform',
				description: 'Before starting the design and waiting for the client\'s response to my questionnaire, I tried to familiarize myself with the Squarespace platform and how to customize the pre-made elements to suit the Oasis concept.',
			},
		],
		planning: {
			id: 'planning',
			title: 'Planning',
			description: 'In order to understand how the new owner of the Oasis yacht planned to market his business, I created a questionnaire to see which aspects of the old business he wanted to maintain or update as well as new avenues to explore. The questionnaire was divided into section topics and sent to the owner and the charter concierge.',
			research: [
				{
					title: 'Basic Information', 
					description: 'Basic services, capacity, distance limitations, house rules and general experience the Oasis could offer.'
				},
				{
					title: 'Pre-boarding', 
					description: 'Any steps guests will need to take before boarding the Oasis--where to start planning, what they might need for daytime or overnight.'
				},
				{
					title: 'Amenities & Features', 
					description: 'I took into account the amenities and features listed in the old site, and asked for more specific details on what each entertaining space, stateroom, and service features could be added to add clarity to what is available in hopes of increasing interest in the renting the vessel.'
				},
				{
					title: 'Charter Rates', 
					description: 'Part 4 through 9 of the questionnaire was to determine what the new rates would be, the crew needs and additional factors to consider based on how long the vessel is being chartered.'
				},
				{
					title: 'Charter Semantics', 
					description: 'Asked about any wording that could potentially be confusing to the guests, like having minimum stay length labeled with "per Day" or "per Night" and whether there are specific check-in/check-out times.'
				},
				{
					title: 'Additional Fees', 
					description: 'Since the Oasis base rates are similar to renting a vehicle or Airbnb, I wanted to clarify any additional fees for special requests, pick-up/drop-off considerations, and if there were cleaning, security deposit, or insurance fees to consider.'
				},
				{
					title: 'Add-on Services', 
					description: 'To help broaden the scope of services from the original "bareboat charters", this section was used to determine if there would be pre-defined "packages" a guest could add on or if there would be full-service concierge to hire for a custom experience.'
				},
				{
					title: 'Recommendations', 
					description: 'In order to inspire guests to charter the Oasis, this section consisted of local excursions, themes and annual events to consider adding to the site.'
				},
				{
					title: 'Miscellaneous', 
					description: 'To wrap up the questionnaire, the last section gave the owner a chance to add any other details/features he wished to feature on the site.'
				},
			],
			researchImages: [
				{ title: 'Part 1: Basic Information', src: require('../assets/images/oasis/Oasis-research1.png'), alt: 'Oasis questionnaire part 1'},
				{ title: 'Part 2: Pre-boarding Preparation', src: require('../assets/images/oasis/Oasis-research2.png'), alt: 'Oasis questionnaire part 2'},
				{ title: 'Part 3: Amenities & Features', src: require('../assets/images/oasis/Oasis-research3.png'), alt: 'Oasis questionnaire part 3'},
				{ title: 'Part 4: 3-hour Charter', src: require('../assets/images/oasis/Oasis-research4.png'), alt: 'Oasis questionnaire part 4'},
				{ title: 'Part 5: 4-hour Charter', src: require('../assets/images/oasis/Oasis-research5.png'), alt: 'Oasis questionnaire part 5'},
				{ title: 'Part 6: Custom Charter', src: require('../assets/images/oasis/Oasis-research6.png'), alt: 'Oasis questionnaire part 6'},
				{ title: 'Part 7: Weekday Overnight Charter', src: require('../assets/images/oasis/Oasis-research7.png'), alt: 'Oasis questionnaire part 7'},
				{ title: 'Part 8: Weekend Overnight Charter', src: require('../assets/images/oasis/Oasis-research8.png'), alt: 'Oasis questionnaire part 8'},
				{ title: 'Part 9: Weekly Charter', src: require('../assets/images/oasis/Oasis-research9.png'), alt: 'Oasis questionnaire part 9'},
				{ title: 'Part 10: Charter Semantics', src: require('../assets/images/oasis/Oasis-research10.png'), alt: 'Oasis questionnaire part 10'},
				{ title: 'Part 11: Additional Fees', src: require('../assets/images/oasis/Oasis-research11.png'), alt: 'Oasis questionnaire part 11'},
				{ title: 'Part 12: Add-on Services', src: require('../assets/images/oasis/Oasis-research12.png'), alt: 'Oasis questionnaire part 12'},
				{ title: 'Part 13: Recommended Trips', src: require('../assets/images/oasis/Oasis-research13.png'), alt: 'Oasis questionnaire part 13'},
				{ title: 'Part 14: Suggested Themes', src: require('../assets/images/oasis/Oasis-research14.png'), alt: 'Oasis questionnaire part 14'},
				{ title: 'Part 15: Miscellaneous', src: require('../assets/images/oasis/Oasis-research15.png'), alt: 'Oasis questionnaire part 15'}
			],
		},
		progress: { 
			id: 'progress',
			title: 'Oasis Project Tracker', 
			description: 'Record of project hours and design information.', 
			src: require('../assets/images/oasis/Oasis-project-tracker.pdf'),
		},
		layout: {
			id: 'layout',
			title: 'Layout',
			description: 'The pages were mainly sectioned to be image focused to enhance that experiential feel and encourage easy scrolling, while the information sections were displayed further down to provide guests with more of the details.',
			pages: [
				{
					id: 'home',
					icon: <HomeIcon />,
					title: 'Home Page', 
					description: 'The home page opens with a full-width image, to capture the overall feel of relaxing in luxury. I wanted this page to be a summary of the site, with experiential images and concise text, encouraging visitors to explore further.',
					image: {title: 'Preview Home Page', src: require('../assets/images/oasis/Oasis-preview-home.png'), alt: 'Oasis home page preview'}
				},
				{
					id: 'about', 
					icon: <InfoOutlinedIcon />,
					title: 'About Pages', 
					description: 'The about sections were made to be the core information hub, with sections on the crew you may be traveling with, how to prepare, who to contact, general yacht etiquette, and of course the FAQ.',
					image: {title: 'Preview About Pages', src: require('../assets/images/oasis/Oasis-preview-about.png'), alt: 'Oasis about page preview'}

				},
				{
					id: 'rates', 
					icon: <SellIcon />,
					title: 'Rates Page', 
					description: 'When booking any service, the way the rates are listed is vital.  The Oasis prefers to cater to a guests individuality and only lists 3 types of rates, a general 4-hour cruise, an overnight charter, and a custom charter.  The intent was to give a starting point and let the experienced captain and crew help cater the experience to suit the guest\'s needs.',
					image: {title: 'Preview Rates Page', src: require('../assets/images/oasis/Oasis-preview-rates.png'), alt: 'Oasis rates page preview'}

				},
				{ 
					id: 'amenities',
					icon: <ListAltIcon />,
					title: 'Amenities Page', 
					description: 'The Oasis can be divided into 3 main sections, the exterior spaces, interior entertaining spaces, and private staterooms below deck. In order to not bog down the visitor with too many specifics, this page was used as a summary of each room available and the features visitors can look forward to on their charter.',
					image: {title: 'Preview Amenities Page', src: require('../assets/images/oasis/Oasis-preview-amenities.png'), alt: 'Oasis amenities page preview'}
				},
				{ 
					id: 'excursion',
					icon: <ExploreOutlinedIcon />,
					title: 'Excursion Pages', 
					description: 'The excursions page was designed to influence visitors to explore numerous destinations, spark ideas for possible celebrations or themed trips, as well as uncover local hidden gems.',
					image: {title: 'Preview Excursion Pages', src: require('../assets/images/oasis/Oasis-preview-excursions.png'), alt: 'Oasis excursion page preview'}
				},
				{ 
					id: 'gallery',
					icon: <PhotoLibraryOutlinedIcon />,
					title: 'Gallery Page', 
					description: 'Because a picture is worth a thousand words.',
					image: {title: 'Preview Gallery Page', src: require('../assets/images/oasis/Oasis-preview-gallery.png'), alt: 'Oasis gallery page preview'}
				},
				{ 
					id: 'contact',
					icon: <EmailIcon />,
					title: 'Contact Page', 
					description: 'The contact section was split into 2 parts, one for general inquiries and another for the charter concierge--who helps guide guests through the planning and preparation for their upcoming booking.',
					image: {title: 'Preview Contact Page', src: require('../assets/images/oasis/Oasis-preview-contact.png'), alt: 'Oasis contact page preview'}
				},
				{ 
					id: 'booking',
					icon: <SailingIcon />,
					title: 'Booking Page', 
					description: 'The "Booking" link is prominently displayed in the upper right corner of every page (for screens larger than mobile), ready to bring guests to the hopeful conclusion of their site visit.',
					image: {title: 'Preview Booking Page', src: require('../assets/images/oasis/Oasis-preview-booking.png'), alt: 'Oasis booking page preview'}
				},
			],
		},
		oldSiteImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/oasis/Oasis-fp-old-home.jpeg'), alt: 'Former site home page' },
			{ page: 'Rates', src: require('../assets/images/oasis/Oasis-fp-old-rates.jpeg'), alt: 'Former site rates page' },
			{ page: 'Amenities', src: require('../assets/images/oasis/Oasis-fp-old-amenities.jpeg'), alt: 'Former site amenities page' },
			{ page: 'Rooms', src: require('../assets/images/oasis/Oasis-fp-old-rooms.jpeg'), alt: 'Former site rooms page' },
			{ page: 'Gallery', src: require('../assets/images/oasis/Oasis-fp-old-gallery.jpeg'), alt: 'Former site gallery page' },
			{ page: 'Contact', src: require('../assets/images/oasis/Oasis-fp-old-contact.jpeg'), alt: 'Former site contact page' },
			{ page: 'Booking', src: require('../assets/images/oasis/Oasis-fp-old-booking.jpeg'), alt: 'Former site booking page' },
		],
		mobileImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/oasis/Oasis-fp-m-home.jpeg'), alt: 'Mobile view of home page' },
			{ page: 'Menu', src: require('../assets/images/oasis/Oasis-fp-m-menu.jpeg'), alt: 'Mobile view of menu overlay' },
			{ page: 'Policies & Etiquette', src: require('../assets/images/oasis/Oasis-fp-m-policies.jpeg'), alt: 'Mobile view of policy page' },
			{ page: 'Planning', src: require('../assets/images/oasis/Oasis-fp-m-planning.jpeg'), alt: 'Mobile view of planning page' },
			{ page: 'Rates', src: require('../assets/images/oasis/Oasis-fp-m-rates.jpeg'), alt: 'Mobile view of rates page' },
			{ page: 'Amenities', src: require('../assets/images/oasis/Oasis-fp-m-amenities.jpeg'), alt: 'Mobile view of amenities page' },
			{ page: 'Points of Interest', src: require('../assets/images/oasis/Oasis-fp-m-poi.jpeg'), alt: 'Mobile view of points of interest page' },
			{ page: 'Recommendations', src: require('../assets/images/oasis/Oasis-fp-m-recommendations.jpeg'), alt: 'Mobile view of recommendations page' },
			{ page: 'Excursion Themes', src: require('../assets/images/oasis/Oasis-fp-m-themes.jpeg'), alt: 'Mobile view of excursion themes page' },
			{ page: 'Gallery', src: require('../assets/images/oasis/Oasis-fp-m-gallery.jpeg'), alt: 'Mobile view of gallery page' },
			{ page: 'Contact', src: require('../assets/images/oasis/Oasis-fp-m-contact.jpeg'), alt: 'Mobile view of general contact page' },
			{ page: 'Concierge', src: require('../assets/images/oasis/Oasis-fp-m-concierge.jpeg'), alt: 'Mobile view of contact concierge page' },
			{ page: 'Booking', src: require('../assets/images/oasis/Oasis-fp-m-booking.jpeg'), alt: 'Mobile view of booking page' },
		],
		tabletImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/oasis/Oasis-fp-t-home.jpeg'), alt: 'Tablet view of Home page' },
			{ page: 'Meet the Team', src: require('../assets/images/oasis/Oasis-fp-t-team.jpeg'), alt: 'Tablet view of team page' },
			{ page: 'Policies & Etiquette', src: require('../assets/images/oasis/Oasis-fp-t-policies.jpeg'), alt: 'Tablet view of policy page' },
			{ page: 'Rates', src: require('../assets/images/oasis/Oasis-fp-t-rates.jpeg'), alt: 'Tablet view of rates page' },
			{ page: 'Amenities', src: require('../assets/images/oasis/Oasis-fp-t-amenities.jpeg'), alt: 'Tablet view of amenities page' },
			{ page: 'Points of Interest', src: require('../assets/images/oasis/Oasis-fp-t-poi.jpeg'), alt: 'Tablet view of points of interest page' },
			{ page: 'Recommendations', src: require('../assets/images/oasis/Oasis-fp-t-recommendations.jpeg'), alt: 'Tablet view of recommendations page' },
			{ page: 'Excursion Themes', src: require('../assets/images/oasis/Oasis-fp-t-themes.jpeg'), alt: 'Tablet view of excursion themes page' },
			{ page: 'Gallery', src: require('../assets/images/oasis/Oasis-fp-t-gallery.jpeg'), alt: 'Tablet view of gallery page' },
			{ page: 'Contact', src: require('../assets/images/oasis/Oasis-fp-t-contact.jpeg'), alt: 'Tablet view of contact page' },
			{ page: 'Booking', src: require('../assets/images/oasis/Oasis-fp-t-booking.jpeg'), alt: 'Tablet view of booking page' },
		],
		desktopImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/oasis/Oasis-fp-d-home.jpeg'), alt: 'Desktop view of Home page' },
			{ page: 'Meet the Team', src: require('../assets/images/oasis/Oasis-fp-d-team.jpeg'), alt: 'Desktop view of team page' },
			{ page: 'Policies & Etiquette', src: require('../assets/images/oasis/Oasis-fp-d-policies.jpeg'), alt: 'Desktop view of policy page' },
			{ page: 'Planning', src: require('../assets/images/oasis/Oasis-fp-d-planning.jpeg'), alt: 'Desktop view of planning page' },
			{ page: 'Rates', src: require('../assets/images/oasis/Oasis-fp-d-rates.jpeg'), alt: 'Desktop view of rates page' },
			{ page: 'Amenities', src: require('../assets/images/oasis/Oasis-fp-d-amenities.jpeg'), alt: 'Desktop view of amenities page' },
			{ page: 'Points of Interest', src: require('../assets/images/oasis/Oasis-fp-d-poi.jpeg'), alt: 'Desktop view of points of interest page' },
			{ page: 'Recommendations', src: require('../assets/images/oasis/Oasis-fp-d-recommendations.jpeg'), alt: 'Desktop view of recommendations page' },
			{ page: 'Excursion Themes', src: require('../assets/images/oasis/Oasis-fp-d-themes.jpeg'), alt: 'Desktop view of excursion themes page' },
			{ page: 'Gallery', src: require('../assets/images/oasis/Oasis-fp-d-gallery.jpeg'), alt: 'Desktop view of gallery page' },
			{ page: 'Contact', src: require('../assets/images/oasis/Oasis-fp-d-contact.jpeg'), alt: 'Desktop view of contact page' },
			{ page: 'Booking', src: require('../assets/images/oasis/Oasis-fp-d-booking.jpeg'), alt: 'Desktop view of booking page' },
		],
		credits: [
			{ 
				name: 'Greg Holloway', 
				title: 'Oasis owner',
				site: 'https://www.oasisyachtseattle.com/team', 
				description: 'For another opportunity to expand my design skills.  I appreciate all your helpful insights on yacht culture and allowing me to experience a trip first hand.',
				tooltip: 'See Greg\'s bio on Oasis site',
			},
			{ 
				name: 'Chaunda VanTassel', 
				title: 'Oasis concierge',
				site: 'https://www.oasisyachtseattle.com/team', 
				description: 'For working closely with me on gathering data for this site and inspiring new ideas to promote the business.',
				tooltip: 'See Chaunda\'s bio on Oasis site',
			},
			{ 
				name: 'Squarespace', 
				title: 'Developer Platform',
				site: 'https://developers.squarespace.com/', 
				description: 'For intuitive components and tutorials.  My first squarespace design experience ran smoothly with your help.',
				tooltip: 'Go to squarespace developers\' site',
			},
			{ 
				name: 'Schwartz-Edmisten', 
				title: 'Web Designers',
				site: 'https://schwartz-edmisten.com/blog/force-an-image-card-to-stack-on-tablet-in-squarespace', 
				description: 'For your assistance in adding another layer of responsive design on squarespace card components.',
				tooltip: 'Go to Schwartz-Edmisten design site',
			},
		],
	},
	{
		id: 'ghp',
		title: 'Greg Holloway Photography',
		subtitle: 'Seattle-based photographer',
		goal: 'Redesign a photography site, previously made using Adobe Dreamweaver.',
		year: '2020',
		site: { title: 'Visit GHP Website', src: 'http://www.greghollowayphotography.com/', icon: <OpenInNewIcon />,  alt: 'Link to GHP website'},
		preview: {
			title: 'Greg Holloway Photography',
			year: '2020',
			image: ghpImg, 
			alt: 'greg holloway photography project',
			summary: 'This photography site features a wide range of photo collections from local parties to international events.',
		},
		client: {
			type: 'Client',
			name: 'Greg Holloway',
			title: 'Photographer',
		},
		srcPrefix: '../images/ghp/',
		avatar: { src: require('../assets/images/ghp/ghp-graphics-avatar.jpg'), alt: 'Greg Holloway Photography Avatar' },
		logo: { src: require('../assets/images/ghp/ghp-graphics-logo-white.jpg'), alt: 'Greg Holloway Photography Logo' },
		logoVariation: { src: require('../assets/images/ghp/ghp-graphics-logo-black.jpg'), alt: 'Greg Holloway Photography Logo' },
		resources: [
			{ frontEnd: ['HTML', 'CSS', 'JavaScript'] },
		],
		videos: {
			mobile: {src: '', alt: ''},
			desktop: {src: '', alt: ''},
		},
		tabs: [
			{name: 'process', icon: <ResearchIcon />, link: '#process' },
			{name: 'planning', icon: <SchemaIcon />, link: '#planning' },
			{name: 'layout', icon: <LayoutIcon />, link: '#layout' },
			{name: 'galleries', icon: <CollectionsIcon />, link: '#galleries' },
		],
		process: [
			{
				id: 'start',
				title: "Starting Point",
				description: 'After a few months into learning web development from an online course on Udemy, my friend Greg had casually mentioned that he was in the process of trying to update his website, but was having trouble finding the time to finish. I took this as a golden opportunity to learn and asked if he would let me take a shot at designing it--free of charge. And so it began...',
			},
			{
				id: 'concept',
				title: 'Concept',
				description: 'For Greg, photography is more of a passion than a business. His vision for the site was to create a "conversation" through the memories captured in his viewfinder--bringing a community of people together to reminisce.',
			},
			{
				id: 'schema',
				title: 'Schema',
				description: 'I kept the overall theme dark, as it would be when developing photos, to block out any distractions or harsh contrast with the images. Touches of silvery blue and grey help to create framing and separation between sections without drawing too much focus.',
			},
			{
				id: 'details',
				title: 'Details',
				description: 'I wanted the image links to have a slightly less constraining feel and designed the frames as a faded gradient that encloses only 2 corners to allow room for a short link description as part of the frame path.  This also applied to the title links, maintaining a consistent partial enclosure throughout the site.  The horizontal rules follow this pattern with the addition of hints of color to create more division between subjects. For little touches of delight, I added hover effects over the main links that were similar to the focus of a viewfinder.  '
			},
		],
		planning: {
			id: 'planning',
			title: 'Planning',
			description: 'I started out by trying to understand how the previous site was laid out and flowed, finding there was very little text or promoting himself as a photographer for hire. It was reminiscent of a closet full of photo books throughout the years, each with a story to tell. Before we even discussed the design for the new site, it was easy to see that this was more personal than business.',
			research: [
				{ 
					title: 'Early Stages',
					description: 'As I was still very new to development and design, the only skills I had to offer were the frontend basics of HTML, CSS, and JavaScript.',
				},
				{ 
					title: 'Challenges',
					description: 'While his previous site had all the basics to express his vision, the layout was not responsive and every element--including text--was converted to an image file and stacked in rows.  In order to make it easy to view on any device, I had to rewrite all the content from scratch.',
				},
				{ 
					title: 'Canvassing',
					description: 'For inspiration, I canvassed photography sites to see if there were any aspects or features that could benefit the design.  But as many were promoting their business, the concepts didn\'t match well with what we wanted to do.  But one feature that stood out on a couple of the sites was having an opening animation to ease you into the site, while giving you a visual summary of what to expect.',
				},
			],
		},
		layout: {
			id: 'layout',
			title: 'Layout',
			description: 'The overall focus of the site was to let the images do the talking, so aside from the exhibitions section where more context is needed, the site is mainly one large photo album. Most of the images are displayed large, rather than using thumbnails, as Greg wanted the visitor to take time to appreciate each photo instead of bypassing them quickly.',
			pages: [
				{ 
					id: 'home',
					icon: <HomeIcon />,
					title: 'Home Page', 
					description: 'The home page was designed as prelude, giving visitors a sense of what to expect in the upcoming galleries.  After several iterations, the opening animations starts with a sequence of four images from each type of gallery--portraits, events, exhibitions, and shows, then fades into a larger collage of work appearing in grayscale and filtering into full color.', 
					image: {title: 'Preview Home Page', src: require('../assets/images/ghp/ghp-preview-home.png'), alt: 'GHP home page preview'}
				},
				{ 
					id: 'burlesque',
					icon: <EighteenUp />,
					title: <Stack direction="row" spacing={2}><WarningIcon color="warning" /><span>Electric Burlesque Page (CW: Nudity)</span></Stack>, 
					description: 'As a fan and friend of burlesque troupes from all around, Greg created the Electric Burlesque series to explore the "art of tease". For this exhibit page I switched from the dark subtle themes present in the other site areas to a more vibrant schema to accompany the neon accents of the images. The beveled framing of each performance image was designed to reflect the handmade light boxes featured at the gallery. Each burlesque dancer\'s inspiration and insights for their performances accompany each frame.  The exhibit page of the event space was neatly laid out to be more like the gallery itself, showcasing the light boxes both lit and unlit. The exhibit in London was featured in several magazines and blogs.  Rather than just posting which magazines the show appeared in, I included the full article along with the prominent media images, to satisfy both those who prefer just a glance and others who want to delve further into the articles.', 
					image: {title: 'Warning: Artwork contains nudity', src: require('../assets/images/ghp/ghp-preview-electric.png'), alt: 'GHP electric burlesque page preview'}
				},
				{ 
					id: 'events',
					icon: <LocalActivityIcon />,
					title: <Stack direction="row" spacing={2}><WarningIcon color="warning"/><span>Events & Shows Page (CW: Nudity)</span></Stack>, 
					description: 'The hub of events is listed chronologically rather than by event, to ease the search for those who visited the site to relive the memories of that time. The individual events have optional views--a grid or a slideshow. The grid is laid out with images in order to feel the sequence of events as it was.  The slideshow view was added to give Greg an opportunity to add music from the event along with the image progression to help relive the moments one by one.', 
					image: {title: 'Warning: Some images contain nudity', src: require('../assets/images/ghp/ghp-preview-events.png'), alt: 'GHP events page preview'}
				},
				{ 
					id: 'exhibits',
					icon: <ImageOutlinedIcon />,
					title: <Stack direction="row" spacing={2}><WarningIcon color="warning" /><span>Exhibits Page (CW: Nudity)</span></Stack>, 
					description: 'The hub of exhibits is divided into "Upcoming" and "Past". The concept for these pages was to create slight variations between exhibits based on the concepts of the photographs. As the burlesque exhibit was the only completed one at the time, the upcoming exhibits are framed previews of selected works from the photographer.', 
					image: {title: 'Warning: Some exhibits contain nudity', src: require('../assets/images/ghp/ghp-preview-exhibits.png'), alt: 'GHP exhibits page preview'}
				},
				{ 
					id: 'portraits',
					icon: <PortraitIcon />,
					title: 'Portraits Page', 
					description: 'The portraits page was designed to feel more like a photo album, with partial frames and images slid into them.', 
					image: {title: 'Preview Portraits Page', src: require('../assets/images/ghp/ghp-preview-portraits.png'), alt: 'GHP portraits page preview'}
				},
				{ 
					id: 'prints',
					icon: <SellIcon />,
					title: 'Fine Art Prints Pages', 
					description: 'The fine art page features select images that are available for purchase similar to an online art store with a card layout and tab separated galleries. However currently this page has a "Coming Soon" placeholder until the pricing has been determined by the owner. Here is a preview of what the page will eventually look like.', 
					image: {title: 'Preview Fine Arts Page', src: require('../assets/images/ghp/ghp-preview-prints.png'), alt: 'GHP prints page preview'}
				},
			]
		},
		improvements: {
			id: 'improvements',
			title: 'Areas of Improvement',
			description: 'As my skills and knowledge were fairly limited upon creation of this website, there are definite areas of improvement that I will be adjusting in the future.',
			updates: [
				{ 
					area: 'Images', 
					issues: 'Due to the size and high quality of the images, they are slow to load especially on the events pages.', 
					actions: 'Next steps would be to optimize the images for the web and different screen sizes.',
				},
				{ 
					area: 'Scalability', 
					issues: 'As each html page is hard coded, the data stored is much larger than when using a framework and component library. The main CSS and JavaScript files however were able to be adjusted to be used across the site with little repetition', 
					actions: 'Next version of the site will most likely be reworked utilizing React and a component library.',
				},
			]
		},
		oldSiteImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/ghp/ghp-fp-old-home.jpeg'), alt: 'Former site home page'},
			{ page: 'Light Painting Exhibit', src: require('../assets/images/ghp/ghp-fp-old-light.jpeg'), alt: 'Former site light painting exhibit page'},
			{ page: 'Electric Burlesque Main', src: require('../assets/images/ghp/ghp-fp-old-electric-main.jpeg'), alt: 'Former site electric burlesque main page'},
			{ page: 'Electric Burlesque Exhibit', src: require('../assets/images/ghp/ghp-fp-old-electric-exhibit.jpeg'), alt: 'Former site electric burlesque exhibit page'},
			{ page: 'Electric Burlesque Press', src: require('../assets/images/ghp/ghp-fp-old-electric-press.jpeg'), alt: 'Former site electric burlesque press page'},
			{ page: 'Events', src: require('../assets/images/ghp/ghp-fp-old-events.jpeg'), alt: 'Former site events page'},
			{ page: 'Event Gallery', src: require('../assets/images/ghp/ghp-fp-old-event-gallery.jpeg'), alt: 'Former site event gallery page'},
			{ page: 'Event Slideshow', src: require('../assets/images/ghp/ghp-fp-old-event-slideshow.jpeg'), alt: 'Former site event slideshow page'},
			{ page: 'Contact', src: require('../assets/images/ghp/ghp-fp-old-contact.jpeg'), alt: 'Former site contact page'},
		],
		mobileImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/ghp/ghp-fp-m-home.jpeg'), alt: 'Mobile view of home page' },
			{ page: 'Exhibits', src: require('../assets/images/ghp/ghp-fp-m-exhibits.jpeg'), alt: 'Mobile view of exhibits page' },
			{ page: 'Light Painting Exhibit', src: require('../assets/images/ghp/ghp-fp-m-light.jpeg'), alt: 'Mobile view of light painting exhibit page' },
			{ page: 'Electric Burlesque Main', src: require('../assets/images/ghp/ghp-fp-m-electric-main.jpeg'), alt: 'Mobile view of electric burlesque main page' },
			{ page: 'Electric Burlesque Exhibit', src: require('../assets/images/ghp/ghp-fp-m-electric-exhibit.jpeg'), alt: 'Mobile view of electric burlesque exhibit page' },
			{ page: 'Electric Burlesque Press', src: require('../assets/images/ghp/ghp-fp-m-electric-press.jpeg'), alt: 'Mobile view of electric burlesque press page' },
			{ page: 'Events', src: require('../assets/images/ghp/ghp-fp-m-events.jpeg'), alt: 'Mobile view of events page' },
			{ page: 'Event Gallery', src: require('../assets/images/ghp/ghp-fp-m-event-gallery.jpeg'), alt: 'Mobile view of event gallery page' },
			{ page: 'Portraits', src: require('../assets/images/ghp/ghp-fp-m-portraits.jpeg'), alt: 'Mobile view of portraits page' },
			{ page: 'Coming Soon', src: require('../assets/images/ghp/ghp-fp-m-coming-soon.jpeg'), alt: 'Mobile view of coming soon page' },
			{ page: 'Fine Art Prints', src: require('../assets/images/ghp/ghp-fp-m-fineart-hide-opt.jpeg'), alt: 'Mobile view of fine art prints page with print options hidden' },
			{ page: 'Fine Art Options', src: require('../assets/images/ghp/ghp-fp-m-fineart-show-open.jpeg'), alt: 'Mobile view of fine art prints page with print options showing' },
			{ page: 'Contact', src: require('../assets/images/ghp/ghp-fp-m-contact.jpeg'), alt: 'Mobile view of contact page' },
		],
		tabletImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/ghp/ghp-fp-t-home.jpeg'), alt: 'Tablet view of home page' },
			{ page: 'Exhibits', src: require('../assets/images/ghp/ghp-fp-t-exhibits.jpeg'), alt: 'Tablet view of exhibits page' },
			{ page: 'Light Painting Exhibit', src: require('../assets/images/ghp/ghp-fp-t-light.jpeg'), alt: 'Tablet view of light painting exhibit page' },
			{ page: 'Electric Burlesque Main', src: require('../assets/images/ghp/ghp-fp-t-electric-main.jpeg'), alt: 'Tablet view of electric burlesque main page' },
			{ page: 'Electric Burlesque Exhibit', src: require('../assets/images/ghp/ghp-fp-t-electric-exhibit.jpeg'), alt: 'Tablet view of electric burlesque exhibit page' },
			{ page: 'Electric Burlesque Press', src: require('../assets/images/ghp/ghp-fp-t-electric-press.jpeg'), alt: 'Tablet view of electric burlesque press page' },
			{ page: 'Events', src: require('../assets/images/ghp/ghp-fp-t-events.jpeg'), alt: 'Tablet view of events page' },
			{ page: 'Event Gallery', src: require('../assets/images/ghp/ghp-fp-t-event-gallery.jpeg'), alt: 'Tablet view of event gallery page' },
			{ page: 'Portraits', src: require('../assets/images/ghp/ghp-fp-t-portraits.jpeg'), alt: 'Tablet view of portraits page' },
			{ page: 'Coming Soon', src: require('../assets/images/ghp/ghp-fp-t-coming-soon.jpeg'), alt: 'Tablet view of coming soon page' },
			{ page: 'Fine Art Prints', src: require('../assets/images/ghp/ghp-fp-t-fineart-hide-opt.jpeg'), alt: 'Tablet view of fine art prints page with print options hidden' },
			{ page: 'Fine Art Options', src: require('../assets/images/ghp/ghp-fp-t-fineart-show-open.jpeg'), alt: 'Tablet view of fine art prints page with print options showing' },
			{ page: 'Contact', src: require('../assets/images/ghp/ghp-fp-t-contact.jpeg'), alt: 'Tablet view of contact page' },
		],
		desktopImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/ghp/ghp-fp-d-home.jpeg'), alt: 'Desktop view of home page' },
			{ page: 'Exhibits', src: require('../assets/images/ghp/ghp-fp-d-exhibits.jpeg'), alt: 'Desktop view of exhibits page' },
			{ page: 'Light Painting Exhibit', src: require('../assets/images/ghp/ghp-fp-d-light.jpeg'), alt: 'Desktop view of light painting exhibit page' },
			{ page: 'Electric Burlesque Main', src: require('../assets/images/ghp/ghp-fp-d-electric-main.jpeg'), alt: 'Desktop view of electric burlesque main page' },
			{ page: 'Electric Burlesque Exhibit', src: require('../assets/images/ghp/ghp-fp-d-electric-exhibit.jpeg'), alt: 'Desktop view of electric burlesque exhibit page' },
			{ page: 'Electric Burlesque Press', src: require('../assets/images/ghp/ghp-fp-d-electric-press.jpeg'), alt: 'Desktop view of electric burlesque press page' },
			{ page: 'Events', src: require('../assets/images/ghp/ghp-fp-d-events.jpeg'), alt: 'Desktop view of events page' },
			{ page: 'Event Gallery', src: require('../assets/images/ghp/ghp-fp-d-event-gallery.jpeg'), alt: 'Desktop view of event gallery page' },
			{ page: 'Event Slideshow', src: require('../assets/images/ghp/ghp-fp-d-event-slideshow.jpeg'), alt: 'Desktop view of event slideshow page' },
			{ page: 'Portraits', src: require('../assets/images/ghp/ghp-fp-d-portraits.jpeg'), alt: 'Desktop view of portraits page' },
			{ page: 'Coming Soon', src: require('../assets/images/ghp/ghp-fp-d-coming-soon.jpeg'), alt: 'Desktop view of coming soon page' },
			{ page: 'Fine Art Prints', src: require('../assets/images/ghp/ghp-fp-d-fineart-hide-opt.jpeg'), alt: 'Desktop view of fine art prints page with print options hidden' },
			{ page: 'Fine Art Options', src: require('../assets/images/ghp/ghp-fp-d-fineart-show-open.jpeg'), alt: 'Desktop view of fine art prints page with print options showing' },
			{ page: 'Contact', src: require('../assets/images/ghp/ghp-fp-d-contact.jpeg'), alt: 'Desktop view of contact page' },
		],
		credits: [
			{ 
				name: 'Greg Holloway', 
				title: 'Seattle-based photographer and site owner',
				site: 'http://www.greghollowayphotography.com/', 
				tooltip: 'Go to photography site', 
				description: 'For the opportunity to grow in my new career path and the freedom to explore design concepts for your photography site.'
			},
			{ 
				name: 'leotran', 
				title: 'Web Developer',
				site: 'https://codepen.io/leotran', 
				tooltip: 'Go to leotran\'s codepen', 
				description: 'For giving me a starting point for the slideshow features.  Thank you for your codepen on a vanilla JavaScript slideshow.'
			},
			{ 
				name: 'Codrops', 
				title: 'Web Development Site',
				site: 'https://tympanus.net/Development/CreativeLinkEffects/#cl-effect-12', 
				tooltip: 'Go to link effects site', 
				description: 'For help with pure CSS link effects.'
			},
		]
	}
]
export default freelance;
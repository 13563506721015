const aboutme = {
	sideImages: {
		pictureOne: {src: require('../assets/images/profile-heart.png'), alt: 'profile picture'},
		pictureTwo: {src: require('../assets/images/profile-hand.png'), alt: 'profile picture'},
		pictureThree: {src: require('../assets/images/profile-tree.png'), alt: 'profile picture'},
		pictureFour: {src: require('../assets/images/profile-street.png'), alt: 'profile picture'},
		pictureFive: {src: require('../assets/images/profile-shoes.png'), alt: 'profile picture'},
	},
	journey: {
		title: 'Hello stranger',
		subtitle: 'A little bit about me...',
		about: 'My journey into the world of tech began just before the Pandemic. After a decade in the hospitality industry, I decided to pursue a more fulfilling career. Guided by a curiosity for development, I built a solid foundation by learning from industry experts and applying my skills in volunteer projects. My passion for front-end development and the joy of crafting delightful interfaces led me to UX Design, a realm that blended tech and creativity in a way I hadn’t experienced before. Now, I’m excited to pursue Product Design as my lifelong career, creating designs that are both visually pleasing and highly functional.',
	},
	workEthic: {
		title: 'Working Together',
		subtitle: "A glimpse of working with me...",
		about: 'Every day, I bring a smile and positivity to the workplace. I’m a firm believer in honoring commitments and maintaining consistency, making me a reliable team player. I thrive on teamwork and have an insatiable appetite for learning and growth. I’m comfortable collaborating with other teams and product managers, and confidently presenting my ideas to larger groups. While design is my craft, I equally value fostering relationships and promoting open communication in the workplace. I’m eager to channel my vibrant energy and collaborative spirit into enriching your team.',
	},
	designThinking: [
		{
			title: 'Form and Function', 
			approach: 'In design, the architectural adage “form follows function” can serve as a starting point. However, it’s just one facet of the design process. I believe that while function can initiate form, they must ultimately harmonize to deliver an optimal user experience. A well-designed UI should not only be functional but also aesthetically pleasing, creating a balanced and engaging user experience. This balance ensures the design is efficient, usable, enjoyable, and satisfying.',
			quote: {
				author: 'Frank Lloyd Wright',
				theory: 'The mission of an architect is to help people understand how to make life more beautiful, the world a better one for living in, and to give reason, rhyme, and meaning to life.',
				src: 'The Harmony of Form and Function (article)',
				site: 'https://magazine.artland.com/when-form-meets-function-frank-lloyd-wright-organic-architecture/',
			},
		},
		{
			title: 'Data Informs Design', 
			approach: 'Data serves as an excellent guide to designing logical websites. An insightful design not only utilizes quantitative data to make decisions, but also attempts to understand the human behavior behind it. Interpreting this data in a meaningful way can create better user experiences and lead to future innovations.',
			quote: {
				author: 'Eugène Viollet-le-Duc',
				theory: 'A rationally designed structure may not necessarily be beautiful, no building can be beautiful that does not have a rationally designed structure.',
				src: 'Northern Architecture (article)',
				site: 'https://www.northernarchitecture.us/architectural-theory/violletleduc-1.html',
			},
		},
	],
}
export default aboutme;
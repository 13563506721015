import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import CollectionsIcon from '@mui/icons-material/Collections';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as ResearchIcon} from '../assets/images/graphics/thinking-process-svgrepo-com.svg';
import { ReactComponent as LayoutIcon} from '../assets/images/graphics/plans-planning-svgrepo-com.svg';
import anniversaryImg from '../assets/images/projects/anniversary-card.png'
/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
const personal = [
	{
		id: 'anniversary',
		title: 'Anniversary Gift',
		subtitle: 'Celebrating 12 lovely years',
		goal: 'This was a gift for our anniversary--a virtual love letter to show my appreciation and a recollection of some of our delightful memories.',
		year: '2020',
		site: { title: 'Visit Anniversary Website', src: 'http://mccool12anniversary.herokuapp.com/', icon: <OpenInNewIcon />,  alt: 'Link to Anniversary website' },
		preview: {
			title: 'Love Letter',
			year: '2020',
			image: anniversaryImg, 
			alt: 'anniversary project',
			summary: 'Anniversary gift for my husband of 12 years.'
		},
		client: {
			type: 'For',
			name: 'Zach',
			title: 'My wonderful husband',
		},
		srcPrefix: '../assets/images/anniversary/',
		avatar: { src: require('../assets/images/anniversary/mc-avatar.jpg'), alt: 'Anniversary avatar' },
		logo: { src: require('../assets/images/anniversary/mc-icon.png'), alt: 'Anniversary icon' },
		resources: [
			{ frontEnd: [ 'HTML', 'CSS', 'JavaScript', 'jQuery', 'Embedded JavaScript' ] },
			{ backEnd: [ 'Express' ] },
		],
		tabs: [
			{name: 'process', icon: <ResearchIcon /> },
			{name: 'layout', icon: <LayoutIcon /> },
			{name: 'improvements', icon: <DesignServicesIcon /> },
			{name: 'gallery', icon: <CollectionsIcon /> },
		],
		process: [
			{
				id: 'start',
				title: "Starting Point",
				description: 'At the beginning I knew I wanted to have a list that would describe my feelings and the game portion came later in the design. I started by creating a single list of items I wanted to display. and eventually grouped them into 3 separate categories based on the beginning of each sentence--"I love your...", "I love it when you...", and "I love...".',
			},
			{
				id: 'schema',
				title: 'Schema',
				description: 'The overall look I was hoping to create was inspired by vintage films, trying to stylize the images with a sense of nostalgia and warmth. I mixed in a bit of blue to the sepia filters, as a reflection of my husband\'s eye color.',
			},
			{
				id: 'challenges',
				title: 'Challenges',
				description: 'I was still learning web development at this point and began this soon after being introduced to jQuery in the course. Due to the simplicity of the site, I utilized jQuery for it\'s easy to apply animation features like "slide" and "fade".  I followed tutorials to help with the card game logic.',
			},
		],
		layout: {
			id: 'layout',
			title: 'Layout',
			description: '',
			pages: [
				{
					id: 'home',
					icon: <HomeIcon />,
					title: 'Home Page', 
					description: 'The opening view of the home page is simply laid out to give the likeness of a greeting card with arrow indications to open it.',
					image: {title: 'Preview Home Page', src: require('../assets/images/anniversary/mc-preview-home.png'), alt: 'Anniversary home page preview'}
				},
				{ 
					id: 'love letter',
					icon: <FavoriteIcon />,
					name: 'Love Letter Page', 
					description: 'The arrow that opens the "letter" section begins with "I just want to say..." as an introduction and to give a visual indication of what to expect, I added an icon that appears when you hover over the arrow revealing an envelope with a heart. Once you open the letter section, there is a music track we enjoyed listening to at the time that automatically plays, but there is a control button to pause it.  Utilizing three categories of sentence beginnings, I created accordion sections with a text carousel to filter through all the items.  As you open each accordion, the text overlaying the main image next to the accordions also changes.',
					image: {title: 'Preview Love Letter Page', src: require('../assets/images/anniversary/mc-preview-letter.png'), alt: 'Anniversary love letter page preview'}
				},
				{ 
					id: 'matching game',
					icon: <PeopleIcon />,
					name: 'Game Page', 
					description: 'Throughout the years we\'ve taken mirrored photographs of each other and I thought it would be fun to create a matching game from those.  As you find the matching pair, an image appears below with an indication of the where we took the picture and a message or inside joke to accompany it.',
					image: {title: 'Preview Game Page', src: require('../assets/images/anniversary/mc-preview-game.png'), alt: 'Anniversary game page preview'}
				},
			],
		},
		improvements: {
			id: 'improvements',
			title: 'Areas of Improvement',
			description: 'There are a couple logic issues with the game portion that need to be addressed.',
			updates: [
				{ 
					area: 'Matching Game',
					type: 'Coding Issue', 
					issues: 'The "reset" button now has a small error that will open the "game finished" modal if you only partially matched the cards.  You need to click it twice to remove that message.', 
					actions: 'To avoid that, I plan on disabling the reset button until the game is complete.  Since the game is on a small scale, this shouldn\'t cause any negative UX issues.',
				},
			]
		},
		mobileImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/anniversary/mc-m-home.png'), alt: 'Mobile view of home page' },
			{ page: 'Love Letter', src: require('../assets/images/anniversary/mc-m-letter-closed.png'), alt: 'Mobile view of love letter page' },
			{ page: 'Love Letter Top', src: require('../assets/images/anniversary/mc-m-letter-open-top.png'), alt: 'Mobile view of love letter page with top accordion open' },
			{ page: 'Love Letter Middle', src: require('../assets/images/anniversary/mc-m-letter-open-mid.png'), alt: 'Mobile view of love letter page with middle accordion open' },
			{ page: 'Love Letter Bottom', src: require('../assets/images/anniversary/mc-m-letter-open-btm.png'), alt: 'Mobile view of love letter page with bottom accordion open' },
			{ page: 'Perfect Match Start', src: require('../assets/images/anniversary/mc-m-game-start.png'), alt: 'Mobile view of matching game page' },
			{ page: 'Perfect Match Partial', src: require('../assets/images/anniversary/mc-m-game-partial.png'), alt: 'Mobile view of matching game page partially complete' },
			{ page: 'Perfect Match Finish', src: require('../assets/images/anniversary/mc-m-game-finish.png'), alt: 'Mobile view of matching game page completed' },
		],
		tabletImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/anniversary/mc-t-home.png'), alt: 'Tablet view of home page' },
			{ page: 'Love Letter', src: require('../assets/images/anniversary/mc-t-letter-closed.png'), alt: 'Tablet view of love letter page' },
			{ page: 'Love Letter Top', src: require('../assets/images/anniversary/mc-t-letter-open-top.png'), alt: 'Tablet view of love letter page with top accordion open' },
			{ page: 'Love Letter Middle', src: require('../assets/images/anniversary/mc-t-letter-open-mid.png'), alt: 'Tablet view of love letter page with middle accordion open' },
			{ page: 'Love Letter Bottom', src: require('../assets/images/anniversary/mc-t-letter-open-btm.png'), alt: 'Tablet view of love letter page with bottom accordion open' },
			{ page: 'Perfect Match Start', src: require('../assets/images/anniversary/mc-t-game-start.png'), alt: 'Tablet view of matching game page' },
			{ page: 'Perfect Match Partial', src: require('../assets/images/anniversary/mc-t-game-partial.png'), alt: 'Tablet view of matching game page partially complete' },
			{ page: 'Perfect Match Finish', src: require('../assets/images/anniversary/mc-t-game-finish.png'), alt: 'Tablet view of matching game page completed' },
		],
		desktopImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/anniversary/mc-d-home.jpeg'), alt: 'Desktop view of home page' },
			{ page: 'Love Letter', src: require('../assets/images/anniversary/mc-d-letter-closed.jpeg'), alt: 'Desktop view of love letter page' },
			{ page: 'Love Letter Top', src: require('../assets/images/anniversary/mc-d-letter-open-top.jpeg'), alt: 'Desktop view of love letter page with top accordion open' },
			{ page: 'Love Letter Middle', src: require('../assets/images/anniversary/mc-d-letter-open-mid.jpeg'), alt: 'Desktop view of love letter page with middle accordion open' },
			{ page: 'Love Letter Bottom', src: require('../assets/images/anniversary/mc-d-letter-open-btm.jpeg'), alt: 'Desktop view of love letter page with bottom accordion open' },
			{ page: 'Perfect Match Start', src: require('../assets/images/anniversary/mc-d-game-start.jpeg'), alt: 'Desktop view of matching game page' },
			{ page: 'Perfect Match Partial', src: require('../assets/images/anniversary/mc-d-game-partial.png'), alt: 'Desktop view of matching game page partially complete' },
			{ page: 'Perfect Match Finish', src: require('../assets/images/anniversary/mc-d-game-finish.png'), alt: 'Desktop view of matching game page completed' },
		],
		credits: [
			{ 
				name: 'Zach',
				title: 'My husband',
				site: null, 
				description: 'For inspiring me to pursue a new career. I love you!',
			},
			{ 
				name: 'Marina Ferreira',
				title: 'Software Engineer from São Paulo, Brazil',
				site: 'https://medium.com/free-code-camp/vanilla-javascript-tutorial-build-a-memory-game-in-30-minutes-e542c4447eae', 
				description: 'For creating a tutorial for the matching game.',
				tooltip: 'See Marina\'s tutorial'
			},
		]
	}
]
export default personal;
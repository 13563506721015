import { createTheme, alpha } from '@mui/material/styles';

let mainTheme = createTheme({
	palette: {
		mode: 'light',
		common: {
			black: '#393c42',
			white: '#f2fcf8',
		},
		baseLight: {
			main: '#f2fcf8',
			contrastText: '#2d423d',
		},
		baseDark: {
			light: '#63666d',
			main: '#393c42',
			dark: '#13161b',
			contrastText: '#fbf3ff',
		},
		primary: { // grayscale
			light: '#dfe2e3',
			main: '#9b9b9b',
			dark: '#6d6d6d',
			alphaDark: alpha('#6d6d6d', 0.6),
			contrastText: '#161616',
		},
		accentPrimary: {
			light: '#4B7895',
			main: '#38586E',
			dark: '#304C5F',
			alphaDark: alpha('#304C5F', 0.6),
			contrastText: '#e4ecf1',
		},
		// accentPrimary: {
		// 	light: '#edffff',
		// 	main: '#baf2e5',
		// 	dark: '#89bfb3',
		// 	alphaDark: alpha('#89bfb3', 0.6),
		// 	contrastText: '#2d423d',
		// },
		secondary: { // mauve
			light: '#c6bdcf',
			main: '#958d9e',
			dark: '#676070',
			contrastText: '#2b222b',
		},
		accentSecondary: { // lavender
			light: '#fffbff',
			main: '#fbf8ff',
			dark: '#c8c5cc',
			contrastText: '#4c4c4c',
		},
		accentLavender: {
			light: '#fffbff',
			main: '#fbf8ff',
			dark: '#c8c5cc',
			contrastText: '#4c4c4c',
		},
		warning: {
			main: '#ef9a9a'
		},
		background: {
      default: '#9b9b9b',
      paper: '#dfe2e3',
    },
		gradient: {
			angledToLavender: 'linear-gradient(45deg, rgba(186, 242, 229, 0.5), rgba(223, 226, 227, 0.5) 50%)',
			angledToMint: 'linear-gradient(45deg, rgba(223, 226, 227, 0.5), rgba(186, 242, 229, 0.5) 60%)',
			navButton: 'linear-gradient(45deg, rgb(186, 242, 229), rgba(186, 242, 229, 0.5) 10%, transparent 30%)',
			menuLinksPrimary: 'linear-gradient(to top, rgba(200, 197, 204, 0.6), transparent 50%)',
			menuLinksSecondary: 'linear-gradient(to top, rgba(237, 255, 255, 0.6), transparent 50%)',
			mintToLavender: 'linear-gradient( rgb(186, 242, 229) 60%, rgb(251, 248, 255))',
			mintToMint: 'linear-gradient( rgb(186, 242, 229) 60%, rgb(137, 191, 179))',
			mintToMauve: 'linear-gradient( rgb(186, 242, 229) 60%, rgb(149, 141, 158))',
			darkToLight: 'linear-gradient(to left, rgb(109, 109, 109), rgb(155, 155, 155), rgb(223, 226, 227))',
		},
    divider: {
			mint: '#89bfb3',
			secondary: '#c6bdcf',
		}
	},
	spacing: 4,
	breakpoints: {
    values: {
      xs: 0,
			xsm: 500,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
	shadows: [
		'none',
		'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(137,191,179,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
		'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(137,191,179,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
		'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(137,191,179,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
		'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(137,191,179,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(137,191,179,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(137,191,179,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
		'0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(137,191,179,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)',
		'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(137,191,179,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
		'0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(137,191,179,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12)',
		'0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(137,191,179,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
		'0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(137,191,179,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(137,191,179,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2), 0px 13px 19px 2px rgba(137,191,179,0.14), 0px 5px 24px 4px rgba(0,0,0,0.12)',
		'0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(137,191,179,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12)',
		'0px 8px 9px -5px rgba(0,0,0,0.2), 0px 15px 22px 2px rgba(137,191,179,0.14), 0px 6px 28px 5px rgba(0,0,0,0.12)',
		'0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(137,191,179,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
		'0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(137,191,179,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12)',
		'0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(137,191,179,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12)',
		'0px 9px 12px -6px rgba(0,0,0,0.2), 0px 19px 29px 2px rgba(137,191,179,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(137,191,179,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2), 0px 21px 33px 3px rgba(137,191,179,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12)',
		'0px 10px 14px -6px rgba(0,0,0,0.2), 0px 22px 35px 3px rgba(137,191,179,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12)',
		'0px 11px 14px -7px rgba(0,0,0,0.2), 0px 23px 36px 3px rgba(137,191,179,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12)',
		'0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(137,191,179,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
	]
})


mainTheme = createTheme(mainTheme, {
	typography: {
		fontFamily: [
			"Quicksand",
			'sans-serif'
		].join(','),
		h1: {
			fontFamily: "Quicksand",
			fontSize: "3.6rem",
			letterSpacing: "0.01em"
		},
		h2: {
			fontFamily: "Quicksand",
			fontSize: "2.8rem",
			letterSpacing: "0.01em"
		},
		h3: {
			fontFamily: "Quicksand",
			fontSize: "2rem",
			letterSpacing: "0.01em"
		},
		h4: {
			fontFamily: "Quicksand",
			fontSize: "1.6rem",
			letterSpacing: "0.03em"
		},
		h5: {
			fontFamily: "Quicksand",
			fontSize: "1.2rem",
			letterSpacing: "0.03em"
		},
		h6: {
			fontFamily: "Quicksand",
			fontSize: "1rem",
			letterSpacing: "0.03em"
		},
		subtitle1: {
			fontFamily: "Source Sans 3",
			fontSize: "1.6rem",
		},
		subtitle2: {
			fontFamily: "Source Sans 3",
		},
		body1: {
			fontFamily: "Source Sans 3",
		},
		body2: {
			fontFamily: "Source Sans 3",
		},
		homeLogo: {
			fontFamily: 'Quill Ink',
			fontSize: '3rem',
			fontVariant: 'small-caps',
			'@media (max-width:600px)': {
				fontSize: '2.4rem',
			},
		},
		homeGreeting: {
			fontFamily: 'Source Sans 3',
			fontSize: '60px',
		},
		homeBody: {
			fontFamily: "Source Sans 3",
			fontSize: "1.2rem",
			display: 'block'
		},
		appbarLogo: {
			fontFamily: 'Quill Ink',
      fontSize: '2rem',
			letterSpacing: '.3rem',
			fontVariant: 'small-caps',
			textDecoration: 'none',
			flexGrow: 1,
			paddingLeft: mainTheme.spacing(4),
			'@media (min-width:900px)': {
				flexGrow: 0,
				padding: mainTheme.spacing(3)
			},
		},
		button: {
			fontFamily: "Source Sans 3",
		},
		caption: {
			fontFamily: "Source Sans 3",
			textTransform: 'uppercase'
		},
		overline: {
			fontFamily: "Source Sans 3",
		},
		tooltip1: {
			fontFamily: "Source Sans 3",
			letterSpacing: "0.08em",
			fontSize: "0.8rem",
			lineHeight: "0.8rem",
			display: 'block'
		},
		tooltip2: {
			fontFamily: "Source Sans 3",
			letterSpacing: "0.08em",
			textTransform: 'uppercase',
			fontSize: "0.8rem",
			display: 'block',
		},
		carouselIndicator: {
			fontFamily: "Source Sans 3",
			textTransform: 'uppercase',
			fontSize: '1rem',
		},
		quote: {
			fontFamily: 'Quill Ink',
      fontSize: '1.1rem',
			lineHeight: '1.1rem',
			letterSpacing: '0.1rem',
			fontStyle: 'italic',
			textAlign: 'justify'
		},
		cite: {
			fontFamily: 'Source Sans 3',
      fontSize: '1rem',
			fontStyle: 'italic',
			textAlign: 'left',
		},
	},
	props: {
    MuiTooltip: {
      arrow: true,
    },
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: mainTheme.palette.baseDark.main
				}
			}
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true
			}
		},
		MuiMenu: {
			styleOverrides: {
				list: {
					backgroundColor: mainTheme.palette.primary.light,
					color: mainTheme.palette.primary.contrastText
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					[mainTheme.breakpoints.down('xsm')]: {
						whiteSpace: 'normal',
					}
				},
				textAlignLeft: {
					[mainTheme.breakpoints.down('xsm')]: {
						textAlign: 'left',
					}
				},
				textAlignRight: {
					[mainTheme.breakpoints.down('xsm')]: {
						textAlign: 'right',
					}
				},
			}
		}
	},
	MuiCssBaseline: {
		styleOverrides: {
			"@font-face": [
				{
					fontFamily: 'Queen',
					fontStyle: 'normal',
					fontDisplay: 'swap',
					fontWeight: 500,
					src: 'url("../assets/fonts/Queen/Queen.ttf") format("truetype")'
				},
				{
					fontFamily: 'Queen Italic',
					fontStyle: 'italic',
					fontDisplay: 'swap',
					fontWeight: 400,
					src: 'url("../assets/fonts/Queen/QueenItalic.ttf") format("truetype")'
				},
				{
					fontFamily: 'Quicksand',
					fontStyle: 'normal',
					fontDisplay: 'swap',
					fontWeight: 500,
					src: 'url("../assets/fonts/Quicksand-VariableFont_wght.ttf") format("truetype")'
				},
				{
					fontFamily: 'Crushed',
					fontStyle: 'normal',
					fontDisplay: 'swap',
					fontWeight: 400,
					src: 'url("../assets/fonts/Crushed/Crushed-Regular.ttf") format("truetype")'
				},
				{
					fontFamily: 'Quill Ink',
					fontStyle: 'normal',
					fontDisplay: 'swap',
					fontWeight: 500,
					src: 'url("../assets/fonts/Quill/QuillInk.ttf") format("truetype")'
				},
				{
					fontFamily: 'Source Sans 3',
					fontStyle: 'normal',
					fontDisplay: 'swap',
					fontWeight: 400,
					src: 'url("../assets/fonts/SourceSans3/SourceSans3-VariableFont_wght.ttf")'
				}
			]
		}
	}
})

export default mainTheme;
import React from 'react';
import CollectionsIcon from '@mui/icons-material/Collections';
import SchoolIcon from '@mui/icons-material/School';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GitHubIcon from '@mui/icons-material/GitHub';
import { ReactComponent as LayoutIcon} from '../assets/images/graphics/plans-planning-svgrepo-com.svg';
import reactColorImg from '../assets/images/projects/react-card.png'
import yelpcampImg from '../assets/images/projects/yelpcamp-card.png';
/* eslint import/no-anonymous-default-export: [2, {'allowObject': true}] */
const educational = [
	{
		id: 'reactcolor',
		title: 'React Colors Project',
		subtitle: 'Capstone project for the Modern React Bootcamp course',
		goal: 'Create a clone of websites like Flat UI Colors and Material UI Colors using React',
		year: '2021',
		site: { title: 'Visit Colors Project', src: 'http://km-react-color-app.herokuapp.com/', icon: <OpenInNewIcon />,  alt: 'Link to my colors project website'},
		ogsite: { title: 'Course Github Page', src: 'https://github.com/Colt/react-colors', icon: <GitHubIcon sx={{ fontSize: 24, marginTop: '-4px'  }} />,  alt: 'Link to bootcamp github page'},
		preview: {
			title: 'React Color Project',
			year: '2021',
			image: reactColorImg, 
			alt: 'react color project',
			summary: 'Capstone project for the "Modern React Bootcamp" course by instructor Colt Steele.',
		},
		github: '',
		client: {
			type: 'Instructor',
			name: 'Colt Steele',
			title: 'Udemy.com',
		},
		srcPrefix: '../images/reactcolor/',
		avatar: { src: require('../assets/images/reactcolor/react-bootcamp-avatar.png'), alt: 'React bootcamp course image' },
		logo: { src: require('../assets/images/reactcolor/udemy-logo.svg'), alt: 'Udemy.com' },
		resources: [
			{ frontEnd: ['React', 'Material UI', 'CSS in JS', 'JavaScript'] },
		],
		videos: {
			mobile: {src: '', alt: ''},
			desktop: {src: '', alt: ''},
		},
		tabs: [
			{name: 'process', icon: <SchoolIcon /> },
			{name: 'planning', icon: <FormatListNumberedIcon /> },
			{name: 'layout', icon: <LayoutIcon /> },
			{name: 'gallery', icon: <CollectionsIcon /> },
		],
		process: [
			{
				id: 'code',
				title: "Code-Along Project",
				description: 'This was the capstone project for a React bootcamp course I took after completing a full-stack web development course.',
			},
			{
				id: 'concept',
				title: 'Concept',
				description: 'The goal of this application was to create a color palette application that utilizes local storage with React. The instructor set up the foundation of this course project around "class-based" components in React, then later introduced hooks and context in subsequent projects.',
			},
			{
				id: 'variation',
				title: 'Variation',
				description: 'During the lessons, I added stylistic and semantic changes along with extra user features.',
			},
		],
		features: {
			id: 'features',
			title: 'Additional Features',
			description: 'In each of the design phases, I tried to add either a new feature or slight style change that I felt would ease the user experience both visually and functionally.',
			pages: [
				{ 
					id: 'home',
					title: 'Home Page', 
					description: 'We set up the home page as a flex container with "starter palettes" from Flat UI Colors to give a preview of the colors of each palette.',
					alterations: [
						{
							id: 'rc-bg', 
							title: 'Background Image', 
							description: 'The original background was an deep blue svg with several colorful and decorative accents pulled from SVGBackgrounds.com.  From the same site, I selected a different pattern svg and altered the colors slightly.',
							explanation: 'The intention was to draw focus more to the color palettes themselves, with a light monochromatic pattern instead of a bold and somewhat busy background element.',
							images: [
								{name: 'Original Background', src: require('../assets/images/reactcolor/rc-bg-og-svg.png'), alt: 'Original svg background'},									
								{name: 'Updated Background', src: require('../assets/images/reactcolor/rc-bg-new-svg.png'), alt: 'Updated svg background'},
							]
						},
						{
							id: 'rc-npb-home', 
							title: 'Create Palette Button', 
							description: 'The CTA for creating a new palette was originally set as link text in the header, however I moved it to fit into the grid of palette links.',
							explanation: 'I felt the button link wasn\'t prominent enough, so I styled it to match the mini palette preview cards and placed it as the first element in the grid.', 
							images: [
								{name: 'Original Create Palette Button', src: require('../assets/images/reactcolor/rc-npb-home-og.png'), alt: 'Original create palette button'},								
								{name: 'Updated Create Palette Button', src: require('../assets/images/reactcolor/rc-npb-home-new.png'), alt: 'Updated create palette button'},
							]
						},
						{
							id: 'rc-delete-icon', 
							title: 'Delete Mini Palette Icon', 
							description: 'Each palette on the home page is able to be deleted.  When hovering over a palette card, the original delete icon appears in the upper right corner flush within the card boundary. I shifted the placement to have the center of the icon box at the corner, so the box itself extends beyond the palette.',
							explanation: 'By adjusting the placement of the icon, I wanted to draw the user\'s eye to the delete action as it extends beyond the grid layout of the palettes. It appears translucent when hovering over the palette and opaque when hovering over the icon.', 
							images: [
								{name: 'Original Delete Icon', src: require('../assets/images/reactcolor/rc-delete-icon-og.png'), alt: 'Original delete icon'},
								{name: 'Updated Delete Icon', src: require('../assets/images/reactcolor/rc-delete-icon-new.png'), alt: 'Updated delete icon'},								
							]
						},
						{
							id: 'rc-delete-modal',
							title: 'Delete Mini Palette Modal',
							description: 'The delete popup message that we designed in the course was simple with two options: "Delete" and "Cancel".  I added a couple more options to the modal--a preview of the palette to be deleted and some wording changes.',
							explanation: 'The addition of the palette preview was for more clarity, just in case a user accidentally clicked the delete icon of the wrong palette.  And for a bit of humor, rather than a short action message, I changed the wording a bit.\nThe delete action now says "Yes, I can\'t look at it anymore. Delete it!." \nAnd the cancel action says "Actually, I want to keep this one...for now".',
							images: [
								{name: 'Original Delete Modal', src: require('../assets/images/reactcolor/rc-delete-modal-og.png'), alt: 'Original delete modal'},
								{name: 'Updated Delete Modal', src: require('../assets/images/reactcolor/rc-delete-modal-new.png'), alt: 'Updated delete modal'},								
							]
						},
					],
				},
				{ 
					id: 'palette-full',
					title: 'View Palette Page', 
					description: 'The individual palette page has more detailed information about the palette colors and the option to view various color levels and copy their values. To maintain a stylistic consistency, I used the svg pattern from the home page as a background for the navigation header and footer, but changed the color to be more neutral and not distract from the palette colors.',
					alterations: [
						{
							id: 'rc-fp-nav-slider', 
							title: 'Color Level Slider', 
							description: 'The course design for the slider component lists the current color level and has a light gray slider track with a round handle to adjust the color level between 100 and 900. I altered the styles of the slider component and added a tooltip to explain its functionality.',
							explanation: 'For the slider component, I wanted to add some visual clarity to its function.  To achieve that, the background of the slider track is a gradient from light to dark with dots to indicate each level shift.',
							images: [
								{name: 'Original Slider', src: require('../assets/images/reactcolor/rc-fp-nav-slider-og.png'), alt: 'Original slider component'},
								{name: 'Updated Slider', src: require('../assets/images/reactcolor/rc-fp-nav-slider-new.png'), alt: 'Updated slider component'},								
							]
						},
						{
							id: 'rc-fp-nav-select', 
							title: 'Color Format Dropdown', 
							description: 'The original feature to change the color format was a dropdown box with hex, rgb, and rgba options.  I replaced the dropdown box with a row of radio selects and added a tooltip hint for each of the color formats.',
							explanation: 'The decision to change this feature was in hopes of drawing more attention to the additional color formats available when a user chooses to copy a color value.',
							images: [
								{name: 'Original Format Change', src: require('../assets/images/reactcolor/rc-fp-nav-select-og.png'), alt: 'Original color value dropdown'},
								{name: 'Updated Format Change', src: require('../assets/images/reactcolor/rc-fp-nav-select-new.png'), alt: 'Updated color value radio select'},								
							]
						},
						{
							id: 'rc-fp-nav-print', 
							title: 'Print Palette Button', 
							description: 'The "Print to PDF" button was a new feature I added.',
							explanation: 'The intention was to make it easier to save all available palette colors and their levels rather than copying them individually. I may alter the pdf layout in the future to match the full palette image.',
							images: [
								{name: 'Print to PDF on Mobile', src: require('../assets/images/reactcolor/rc-fp-nav-print-sm.png'), alt: 'Print to pdf button on mobile'},
								{name: 'Print to PDF on Large Screen', src: require('../assets/images/reactcolor/rc-fp-nav-print-lg.png'), alt: 'Print to pdf button on desktop'},								
							]
						},
						{
							id: 'rc-fp-hover',
							title: 'Hover Effects',
							description: 'Originally when hovering over a color box some text would fade in prompting the user to "copy" that color. The user could click anywhere in the color box to trigger that action.  I adjusted the trigger to be focused within the copy button itself instead of the entire box and added a hover effect to the button. There is also a new hover effect on the "more" button which takes you to a page displaying all the levels of a single color.',
							explanation: 'Moving the trigger to the copy button itself was to reduce accidental copy animations.  And adding an arrow icon when hovering over the "more" button was to help clarify that the user would be directed to a new area.',
							images: [
								{ name: 'Original Hover Effect', src: require('../assets/images/reactcolor/rc-fp-hover-og.png'), alt: 'Original color box hover effects'},
								{ name: 'Updated Hover Effect', src: require('../assets/images/reactcolor/rc-fp-hover-new.png'), alt: 'Updated color box hover effects'},								
							]
						},
					],
				},
				{ 
					id: 'create-palette',
					title: 'Create Palette Page', 
					description: 'The page to create your own palette has the most features on the site including "drag and drop" to rearrange each color block.  I added a few new features to aid users when selecting colors, viewing current color values and saving the palette.',
					alterations: [
						{
							id: 'rc-cp-start-modal', 
							title: 'Opening Modal', 
							description: 'In the course project, clicking on the "Create Palette" button will immediately take you to the blank palette page.  I added an opening modal to prompt the user to choose between starting with a blank canvas or an existing palette.',
							explanation: 'The purpose of this feature was to help the user with an initial starting point, taking into account that not everyone has an idea of where to begin when only presented with a blank canvas.',
							images: [
								{name: 'Updated Starting Point Modal', src: require('../assets/images/reactcolor/rc-cp-start-modal.png'), alt: 'Opening modal for create palette page'},								
							]
						},
						{
							id: 'rc-cp-drawer', 
							title: 'Drawer Styles', 
							description: 'The palette design drawer component was set up to house all the functionality when building your own palette.',
							explanation: 'To maintain graphic consistency, I reused the svg pattern and neutral colors from the view palette navbar header.',
							images: [
								{name: 'Original Drawer', src: require('../assets/images/reactcolor/rc-cp-drawer-og.png'), alt: 'Original design palette drawer'},
								{name: 'Updated Drawer', src: require('../assets/images/reactcolor/rc-cp-drawer-new.png'), alt: 'Updated design palette drawer'},								
							]
						},
						{
							id: 'rc-cp-drawer-btn', 
							title: 'Drawer Buttons', 
							description: 'The original course design for the drawer included a random color, clear palette, and add color button. I added a button to choose a starter palette and altered the button styles.',
							explanation: 'The "Choose a Starter Palette" button was placed here to trigger the opening modal in case a user changes their mind and wants some starting colors instead of a blank canvas. It is disabled when the palette is not blank. Instead of using the material ui standard colors for the other buttons, I wanted the colors of each button to assist with explaining its functionality.  I changed the clear palette button to be an off-white and the random color button to be a rainbow gradient.',
							images: [
								{name: 'Original Buttons', src: require('../assets/images/reactcolor/rc-cp-drawer-btn-og.png'), alt: 'Original design palette buttons'},
								{name: 'Updated Buttons', src: require('../assets/images/reactcolor/rc-cp-drawer-btn-new.png'), alt: 'Updated design palette buttons'},								
							]
						},
						{
							id: 'rc-cp-picker', 
							title: 'Color Picker', 
							description: 'The original design only had one color picker, the "Chrome Picker".',
							explanation: 'To assist users who may find the chrome color picker difficult, I added the swatch color picker--the layout is similar to paint samples and may give users a familiar starting point.',
							images: [
								{name: 'Updated Chrome Picker', src: require('../assets/images/reactcolor/rc-cp-picker-chrome-new.png'), alt: 'Chrome color picker'},
								{name: 'Updated Swatch Picker', src: require('../assets/images/reactcolor/rc-cp-picker-swatch-new.png'), alt: 'Swatch color picker'},								
							]
						},
						{
							id: 'rc-cp-show-values',
							title: 'View Color Values',
							description: 'The original design of the navigation bar had the title, a button to return to home, and a button to save the palette.  I added an icon to show/hide the color values on the current palette.',
							explanation: 'Before, once a color was added the only indication of the value is the name chosen by the user. I wanted the user to have access to any of the palette colors in case they wanted to base a new color off of an existing one.',
							images: [
								{ name: 'Updated View Icon', src: require('../assets/images/reactcolor/rc-cp-show-icon-new.png'), alt: 'View color values icon'},
								{ name: 'Showing Color Values', src: require('../assets/images/reactcolor/rc-cp-show-values-new.png'), alt: 'View color values palette'},								
							]
						},
						{
							id: 'rc-cp-nav-buttons',
							title: 'Responsive Navbar Buttons',
							description: 'The original design of the navigation bar maintains the title and button values on a mobile screen.  I altered the elements to be more responsive on a smaller screen.',
							explanation: 'When the screen goes down to mobile, I removed the page title and adjusted the button text to change to "Home" and "Save" icons. When the drawer opens on mobile, I also hid all the navbar elements.',
							images: [
								{ name: 'Original Mobile Navbar', src: require('../assets/images/reactcolor/rc-cp-nav-buttons-og.png'), alt: 'Original navbar responsive buttons'},
								{ name: 'Updated Mobile Navbar', src: require('../assets/images/reactcolor/rc-cp-nav-buttons-new.png'), alt: 'Updated navbar responsive buttons'},								
							]
						},
						{
							id: 'rc-cp-save-name',
							title: 'Save Palette Name Dialog',
							description: 'The original prompt to decide a palette name is a basic material-ui dialog box with a button to cancel and a button to save. I added some stylistic and semantic changes.',
							explanation: 'I utilized the pattern svg on the dialog title and changed the text of the cancel button to "On second thought..." and the save button to "Next".',
							images: [
								{ name: 'Original Save Palette Modal', src: require('../assets/images/reactcolor/rc-cp-save-name-og.png'), alt: 'Original save palette name dialog box' },
								{ name: 'Updated Save Palette Modal', src: require('../assets/images/reactcolor/rc-cp-save-name-new.png'), alt: 'Updated save palette name dialog box' },								
							]
						},
						{
							id: 'rc-cp-save-emoji',
							title: 'Save Palette Emoji Dialog',
							description: 'After saving the palette name, another dialog box pops up to prompt the user to pick an emoji for the new palette.  Once the emoji is clicked, the palette automatically saves.  I added more styling and context to assist the user.',
							explanation: 'I maintained the title box styles from the previous dialog to keep consistency and added the palette name to the title.  I modified the functionality so the user could preview the selected emoji before saving and added a button to go back to the previous dialog in case the user decided to change the palette name.',
							images: [
								{ name: 'Original Save Palette Emoji', src: require('../assets/images/reactcolor/rc-cp-save-emoji-og.png'), alt: 'Original save palette emoji dialog box' },
								{ name: 'Updated Save Palette Emoji', src: require('../assets/images/reactcolor/rc-cp-save-emoji-new.png'), alt: 'Updated save palette emoji dialog box' },								
							]
						},
					],
				},
			]
		},
		improvements: {
			id: 'improvements',
			title: 'Areas of Improvement',
			description: '',
			updates: [
				{ 
					area: 'Create Palette Page Text', 
					type: 'Display Issue',
					issues: 'Text does not dynamical respond to the background luminosity like the other palette pages', 
					actions: 'Need to apply darker text on lighter backgrounds and lighter text on darker backgrounds.',
				},
				{ 
					area: 'Create Palette Page Color Values', 
					type: 'Styling Issue',
					issues: 'On mobile screens when showing the color values, the text overlaps the color name.', 
					actions: 'Need to add a conditional on mobile only where either the name or the value is displayed instead of both.',
				},
			]
		},
		oldSiteImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/reactcolor/rc-home-og.png'), alt: 'Course designed home page' },
			{ page: 'Full Palette', src: require('../assets/images/reactcolor/rc-palette-og.png'), alt: 'Course designed palette page' },
			{ page: 'Save Palette', src: require('../assets/images/reactcolor/rc-cp-main-og.png'), alt: 'Course designed save palette page' },
		],
		mobileImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/reactcolor/rc-m-home.png'), alt: 'Mobile view home page' },
			{ page: 'Full Palette', src: require('../assets/images/reactcolor/rc-m-full-palette.png'), alt: 'Mobile view full palette page' },
			{ page: 'Single Color Palette', src: require('../assets/images/reactcolor/rc-m-single-palette.png'), alt: 'Mobile view single color palette page' },
			{ page: 'Create Palette Open Drawer', src: require('../assets/images/reactcolor/rc-m-create-drawer.png'), alt: 'Mobile view create palette page with open drawer' },
			{ page: 'Create Palette Collapsed Drawer', src: require('../assets/images/reactcolor/rc-m-create-nodrawer.png'), alt: 'Mobile view create palette page with collapsed drawer' },
		],
		tabletImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/reactcolor/rc-t-home.png'), alt: 'Tablet view home page' },
			{ page: 'Full Palette', src: require('../assets/images/reactcolor/rc-t-full-palette.png'), alt: 'Tablet view full palette page' },
			{ page: 'Single Color Palette', src: require('../assets/images/reactcolor/rc-t-single-palette.png'), alt: 'Tablet view single color palette page' },
			{ page: 'Create Palette Open Drawer', src: require('../assets/images/reactcolor/rc-t-create-drawer.png'), alt: 'Tablet view create palette page with open drawer' },
			{ page: 'Create Palette Collapsed Drawer', src: require('../assets/images/reactcolor/rc-t-create-nodrawer.png'), alt: 'Tablet view create palette page with collapsed drawer' },
		],
		desktopImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/reactcolor/rc-d-home.png'), alt: 'Desktop view home page' },
			{ page: 'Full Palette', src: require('../assets/images/reactcolor/rc-d-full-palette.png'), alt: 'Desktop view full palette page' },
			{ page: 'Single Color Palette', src: require('../assets/images/reactcolor/rc-d-single-palette.png'), alt: 'Desktop view single color palette page' },
			{ page: 'Create Palette Open Drawer', src: require('../assets/images/reactcolor/rc-d-create-drawer.png'), alt: 'Desktop view create palette page with open drawer' },
			{ page: 'Create Palette Collapsed Drawer', src: require('../assets/images/reactcolor/rc-d-create-nodrawer.png'), alt: 'Desktop view create palette page with collapsed drawer' },
		],
		credits: [
			{ 
				name: 'Udemy Online Learning', 
				title: 'The Modern React Bootcamp',
				site: 'https://www.udemy.com/course/modern-react-bootcamp/', 
				description: 'For providing affordable education for career-changers and a variety of subjects to explore.',
				tooltip: 'Go to bootcamp course'
			},
			{ 
				name: 'Colt Steele', 
				title: 'Full stack web developer, coding bootcamp instructor and Udemy course instructor',
				site: 'https://github.com/Colt', 
				description: 'For expanding my development skills and putting together intriguing code-along projects.',
				tooltip: 'See Colt\'s github'
			},
		],
	},
	{
		id: 'yelpcamp',
		title: 'YelpCamp Project',
		subtitle: 'Capstone project from Colt Steele\'s "Web Development Bootcamp" course',
		goal: 'Create a community website to share campground locations along with authentication.',
		year: '2020',
		site: { title: 'Visit Yelpcamp Project', src: 'http://km-yelpcamp.herokuapp.com/', icon: <OpenInNewIcon />,  alt: 'Link to my yelpcamp website'},
		ogsite: { title: 'Course Github Page', src: 'https://github.com/Colt/YelpCamp/', icon: <GitHubIcon sx={{ fontSize: 24, marginTop: '-4px' }} />,  alt: 'Link to bootcamp github page'},
		preview: {
			title: 'Yelpcamp Project',
			year: '2020',
			image: yelpcampImg, 
			alt: 'yelpcamp project',
			summary: 'Capstone project for the "Web Development Bootcamp" course by instructor Colt Steele.',
		},
		github: '',
		client: {
			type: 'Instructor',
			name: 'Colt Steele',
			title: 'Udemy.com',
		},
		srcPrefix: '../images/yelpcamp/',
		avatar: { src: require('../assets/images/yelpcamp/bootcamp-avatar.png'), alt: 'Colt Steele\'s bootcamp avatar' },
		logo: { src: require('../assets/images/yelpcamp/udemy-logo.svg'), alt: 'Udemy.com logo' },
		resources: [
			{ frontEnd: ['HTML', 'CSS', 'JavaScript', 'Embedded JavaScript', 'Bootstrap'] },
			{ backEnd: [ 'Node', 'Express', 'Mongoose', 'Passport' ] },
			{ database: [ 'Cloudinary', 'MongoDB'] },
		],
		videos: {
			mobile: {src: '', alt: ''},
			desktop: {src: '', alt: ''},
		},
		tabs: [
			{name: 'process', icon: <SchoolIcon /> },
			{name: 'features', icon: <FormatListNumberedIcon /> },
			{name: 'improvements', icon: <DesignServicesIcon /> },
			{name: 'gallery', icon: <CollectionsIcon /> },
		],
		process: [
			{
				id: 'code',
				title: "Code-Along Project",
				description: 'This was the capstone project for the bootcamp course I took when I started learning web development.  It has since been refactored by the instructor and I plan to return to the course for a refresh as well as revamping the project with the exciting new features that were added, like authentication from scratch, geocoding and cluster maps.',
			},
			{
				id: 'concept',
				title: 'Concept',
				description: 'The main goal of this project was to expose the students to popular frontend/backend tools, best practices and debugging.',
			},
			{
				id: 'variation',
				title: 'Variation',
				description: 'After the project was completed, I went back to add a few of my own stylings and attempted to add a basic user page (a work in progress).',
			},
		],
		features: {
			id: 'features',
			title: 'Additional Features',
			description: 'Since this was early in my development experience, I did not attempt to add a bunch of new features at the time. The main difference in my site compared to the instructor\'s was the addition of the "User Information" page, which is currently incomplete.',
			pages: [
				{ 
					id: 'user',
					title: 'User Page', 
					description: 'A work in progress.',
					alterations: [
						{
							id: 'yc-user', 
							title: 'User Profile Content', 
							description: 'The goal of this page is to connect users through shared interests.',
							explanation: 'When I am able to revisit the instructor\'s revised course, the plan is to add basic user information along with sections for user guides/tips for camping.',
							images: [
								{name: 'Desktop User Profile Page', src: require('../assets/images/yelpcamp/yc-d-user-new.png'), alt: 'Desktop screen user profile page'},
								{name: 'Tablet User Profile Page', src: require('../assets/images/yelpcamp/yc-t-user.png'), alt: 'Tablet screen user profile page'},								
							]
						},
						{
							id: 'yc-likes-modal', 
							title: 'Likes Modal', 
							description: 'When visiting a campground show page, there is a "Likes" button displaying the total number of likes.  In the original, when clicking this button, a popup appears listing the names of the users who like it.',
							explanation: 'To have easy access to other users\' pages with similar campground likes, I added a button beside each name in the popup that will take the current user to the targeted user\'s profile page.',
							images: [
								{name: 'New Likes Modal', src: require('../assets/images/yelpcamp/yc-d-campground-likes-modal-new.png'), alt: 'New likes modal user list.'},								
							]
						},
					],
				},
				{ 
					id: 'comparison',
					title: 'Old vs. New Styles', 
					description: 'After completing the course, I revisited the stylesheets and modified the layout and palette of the original walkthrough.',
					alterations: [
						{
							id: 'yc-compare-home', 
							title: 'Home Page', 
							description: 'The original home page had a hero image and cards displaying all the campgrounds with pagination.',
							explanation: 'Since the site was focused around the outdoors, I opted for deep forest greens as the base palette. I also added a truncated summary of the campground within the cards that shows on larger screens only.',
							images: [
								{name: 'Old Home Page', src: require('../assets/images/yelpcamp/yc-d-home-og.png'), alt: 'Original YelpCamp home page'},
								{name: 'New Home Page', src: require('../assets/images/yelpcamp/yc-d-home-new.png'), alt: 'New YelpCamp home page'},								
							]
						},
						{
							id: 'yc-compare-campgrounds', 
							title: 'Campgrounds Show Page', 
							description: 'The original course campground detail page had a side bar for information on the campground, but we didn\'t add any during the lesson, so I removed it for the time being and modified the styles.',
							explanation: 'I moved the price to be more prominent by overlaying it at the top of the campground image and altered the colors to match the new theme.',
							images: [
								{name: 'Original Show Page', src: require('../assets/images/yelpcamp/yc-d-campground-show-og.png'), alt: 'Original Yelpcamp show page'},
								{name: 'New Show Page', src: require('../assets/images/yelpcamp/yc-d-campground-show-new.png'), alt: 'New Yelpcamp show page'},								
							]
						},
						{
							id: 'yc-compare-forms', 
							title: 'Forms', 
							description: 'The original Yelpcamp forms were drawn from the basic Bootstrap styles, so I updated them to match the new theme.',
							explanation: 'To allow for more space to write comments and campground information, I switched to a <textarea> tag instead of an <input> tag',
							images: [
								{name: 'Original Yelpcamp Forms', src: require('../assets/images/yelpcamp/yc-d-allforms-og.png'), alt: 'Original Yelpcamp forms pages'},
								{name: 'New Yelpcamp Forms', src: require('../assets/images/yelpcamp/yc-d-allforms-new.png'), alt: 'New Yelpcamp forms pages'},								
							]
						},
					],
				},
			],
		},
		improvements: {
			id: 'improvements',
			title: 'Areas of Improvement',
			description: '',
			updates: [
				{ 
					area: 'Theme Consistency', 
					type: 'Styling Issue',
					issues: 'There are a couple areas where the buttons do not match the theme.', 
					actions: 'Need to revisit CSS files to style.',
				},
				{ 
					area: 'New Material', 
					type: 'Out of Date',
					issues: 'As I mentioned before, it has been a couple years since this site was made for educational practice and since then the course has added new features I would like to learn and apply.', 
					actions: 'Need to revisit the course for updated material.',
				},
				{ 
					area: 'Form Submission', 
					type: 'User Experience Update',
					issues: 'When I go back to rework the site and add in the newer course material, I would like to try and modify the comment/review forms to trigger a modal instead of routing to a new page and then back again.', 
					actions: 'Testing to see if perhaps keeping the page still slightly visible in the background would help when adding or editing a comment/review. Or perhaps rather than a modal, allow the editing to happen directly in the card.',
				},
				{
					area: 'Information Consistency',
					type: 'User Experience Update',
					issues: 'As the forms are now, the information you can give is open to interpretation. Having a more detailed form when adding a campground will help keep information consistent across the site.',
					actions: 'I plan to split the large text area into smaller categories like "Facilities", "Rules and Regulations", "Nearby Attractions", etc.  This way the author can keep the information neatly organized within categories for other users to easily view. ',
				},
			]
		},
		oldSiteImages: [
			{ page: ''},
			{ page: 'Home', src: require('../assets/images/yelpcamp/yc-d-home-og.png'), alt: 'Course designed home page' },
			{ page: 'Campground', src: require('../assets/images/yelpcamp/yc-d-campground-show-og.png'), alt: 'Course designed campground page' },
			{ page: 'Login Form', src: require('../assets/images/yelpcamp/yc-d-login-form-og.png'), alt: 'Course designed login page' },
			{ page: 'Campground Form', src: require('../assets/images/yelpcamp/yc-d-campground-form-og.png'), alt: 'Course designed add campground page' },
			{ page: 'Comment Form', src: require('../assets/images/yelpcamp/yc-d-comment-form-og.png'), alt: 'Course designed add comment page' },
		],
		mobileImages: [
			{ page: ''},
			{ page: 'Landing', src: require('../assets/images/yelpcamp/yc-fp-m-landing.jpeg'), alt: 'Mobile view landing page'},
			{ page: 'Home', src: require('../assets/images/yelpcamp/yc-fp-m-home.jpeg'), alt: 'Mobile view home page'},
			{ page: 'Visitor Campground', src: require('../assets/images/yelpcamp/yc-fp-m-show-visitor.jpeg'), alt: 'Mobile view campground page'},
			{ page: 'Author Campground', src: require('../assets/images/yelpcamp/yc-fp-m-show-author.jpeg'), alt: 'Mobile view campground page'},
			{ page: 'User Profile', src: require('../assets/images/yelpcamp/yc-fp-m-user.jpeg'), alt: 'Mobile view user profile page'},
		],
		tabletImages: [
			{ page: ''},
			{ page: 'Landing', src: require('../assets/images/yelpcamp/yc-fp-t-landing.jpeg'), alt: 'Tablet view landing page'},
			{ page: 'Home', src: require('../assets/images/yelpcamp/yc-fp-t-home.jpeg'), alt: 'Tablet view home page'},
			{ page: 'Visitor Campground', src: require('../assets/images/yelpcamp/yc-fp-t-show-visitor.jpeg'), alt: 'Tablet view campground page'},
			{ page: 'Author Campground', src: require('../assets/images/yelpcamp/yc-fp-t-show-author.jpeg'), alt: 'Tablet view campground page'},
			{ page: 'User Profile', src: require('../assets/images/yelpcamp/yc-fp-t-user.jpeg'), alt: 'Tablet view user profile page'},
		],
		desktopImages: [
			{ page: ''},
			{ page: 'Landing', src: require('../assets/images/yelpcamp/yc-fp-d-landing.jpeg'), alt: 'Desktop view landing page'},
			{ page: 'Home', src: require('../assets/images/yelpcamp/yc-fp-d-home.jpeg'), alt: 'Desktop view home page'},
			{ page: 'Visitor Campground', src: require('../assets/images/yelpcamp/yc-fp-d-show-visitor.jpeg'), alt: 'Desktop view campground page'},
			{ page: 'Author Campground', src: require('../assets/images/yelpcamp/yc-fp-d-show-author.jpeg'), alt: 'Desktop view campground page'},
			{ page: 'User Profile', src: require('../assets/images/yelpcamp/yc-fp-d-user.jpeg'), alt: 'Desktop view user profile page'},
		],
		credits: [
			{ 
				name: 'Udemy Online Learning', 
				title: 'The Web Developer Bootcamp 2020',
				site: 'https://www.udemy.com/course/the-web-developer-bootcamp/', 
				description: 'For providing affordable education for career-changers.',
				tooltip: 'Go to bootcamp course'
			},
			{ 
				name: 'Colt Steele', 
				title: 'Full stack web developer, coding bootcamp instructor and Udemy course instructor',
				site: 'https://github.com/Colt', 
				description: ' For my introduction into web development. Your lessons were well paced even for complete beginners.  Thank you for sharing best practices and exposing your students to potential errors to help us troubleshoot issues. I look forward to revisiting the course updates made on the bootcamp material.',
				tooltip: 'See Colt\'s github'
			},
			{ 
				name: 'Ian Schoonover', 
				title: 'Full stack web developer, coding bootcamp instructor, and teaching assistant',
				site: 'https://github.com/nax3t', 
				description: 'For assisting students with this bootcamp course.  Thank you for updating videos and answering our questions when new material was introduced.',
				tooltip: 'See Ian\'s github'
			},
		],
	}
]
export default educational;
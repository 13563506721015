import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grow from '@mui/material/Grow';

const ScrollToTop = () => {
  const {pathname} = useLocation();
  const trigger = useScrollTrigger();
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <Grow in={trigger}>
      <Fab 
				onClick={scrollToTop} 
				color="baseDark"
				size="small" 
				aria-label="scroll back to top" 
				sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1500 }}
			>
        <KeyboardArrowUpIcon />
      </Fab>
    </Grow>
  );
}
export default ScrollToTop;
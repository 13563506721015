/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import { Link, useLocation } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NavbarButtons from './NavbarButtons';
import NavbarDrawers from './NavbarDrawers';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { StyledAppBar } from '../styles/NavbarStyles';

const HideOnScroll = ({children, window}) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Navbar = (props) => {
  const location = useLocation();
  const projectLinks = [
    {id: 'freelance-projects', name: 'Freelance', subRoute: '/freelance/oasis'},
    {id: 'educational-projects', name: 'Educational', subRoute: '/educational/reactcolor'},
    {id: 'personal-projects', name: 'Personal', subRoute: '/personal/anniversary'}
  ];
  const aboutLinks = [
    {id: 'about-me', name: 'About Me', subRoute: '/about'},
    {id: 'college-projects', name: 'Memory Lane', subRoute: '/college'},
  ];
  return (
    <HideOnScroll {...props}>
    <StyledAppBar location={location.pathname}>
      <Container maxWidth='xl' >
        <Toolbar disableGutters >
          <Typography variant='appbarLogo' noWrap component={Link} to={'/'}>
            KM | Canvas
          </Typography>
          <NavbarButtons projectLinks={projectLinks} aboutLinks={aboutLinks}/>
          <NavbarDrawers projectLinks={projectLinks} aboutLinks={aboutLinks}/>
        </Toolbar>
      </Container>
    </StyledAppBar>
    </HideOnScroll>
  );
};
export default Navbar;
